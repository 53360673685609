import $ from 'jquery';
import AsyncPreviewController from './async_preview_controller';

export default class extends AsyncPreviewController {
  static targets = [
    'htmlEditorButton',
  ];

  connect() {
    super.connect();

    this.shouldShowRawEditor = false;

    document.addEventListener('trix-initialize', () => {
      this.attachLiquidTagSelect();
    });
  }

  attachLiquidTagSelect() {
    const liquidOptions = JSON.parse(this.data.get('options'));

    const selectTag = document.createElement('select');
    selectTag.setAttribute('id', 'insert-liquid-tags-select');
    selectTag.classList.add('trix-button');
    selectTag.addEventListener('change', (event) => {
      const { target } = event;

      // blank is default text
      if (target.value === '') {
        return;
      }

      const { editor } = this.element.querySelector('trix-editor');
      editor.insertString(`{{${target.value}}}`);

      // Reselect the blank option because the drop down control looks weird with one of the tags selected
      target.value = '';
    });

    const option = document.createElement('option');
    option.innerHTML = 'Insert Values';
    option.value = '';
    option.setAttribute('selected', 'selected');
    option.setAttribute('disabled', 'disabled');
    selectTag.appendChild(option);

    $.each(liquidOptions, (i, eachOption) => {
      const element = document.createElement('option');
      element.innerHTML = eachOption.text;
      element.value = eachOption.value;
      selectTag.appendChild(element);
    });

    const toolbar = this.element.querySelector('trix-toolbar .trix-button-group--block-tools');
    toolbar.appendChild(selectTag);
  }

  toggleRawEditor(event) {
    if (!(event == null)) event.preventDefault();

    this.shouldShowRawEditor = !this.shouldShowRawEditor;

    if (this.shouldShowRawEditor) {
      this.htmlEditorButtonTarget.innerHTML = 'Hide HTML Editor';
      this.showRawEditor();
    } else {
      this.htmlEditorButtonTarget.innerHTML = 'Show HTML Editor';
      this.closeRawEditor();
    }
  }

  closeRawEditorIfNecessary() {
    if (this.shouldShowRawEditor) {
      this.toggleRawEditor();
    }
  }

  showRawEditor() {
    const newInput = document.createElement('textarea');
    newInput.setAttribute('name', 'rawHtml');
    newInput.classList.add('form-control');
    newInput.classList.add('trix-raw-editor');
    newInput.value = this.inputTarget.value;

    newInput.addEventListener('input', () => {
      // Directly update the hidden input. This will trigger a refresh of the preview if visible. The trix editor will not be updated until the raw editor is closed, because it is a very costly operation to update and it will steal the focus from the raw textarea
      this.inputTarget.value = newInput.value;
    });

    const wrapper = this.element.querySelector('.trix-editor-wrapper');
    wrapper.appendChild(newInput);
  }

  closeRawEditor() {
    const rawInput = this.element.querySelector('.trix-raw-editor');
    const { editor } = this.element.querySelector('trix-editor');
    editor.loadHTML(rawInput.value);
    rawInput.remove();
  }
}
