import { Controller } from '@hotwired/stimulus';
import { formatGeneral } from 'cleave-zen';

export default class extends Controller {
  static targets = [
    'businessInfoForm',
    'dontWorryDiv',
    'nonprofitForm',
    'publicPrivateForm',
    'soleProprietorWarningDiv',
    'publicForm',
    'businessNameForm',
    'selectBusinessType',
    'businessRegistrationIdentifierField',
    'businessRegistrationNumberField',
  ];

  connect() {
    this.fixRequiredFields();
    this.businessRegistrationIdentifier = this.businessRegistrationIdentifierFieldTarget.value;

    const controller = this;
    this.businessRegistrationNumberFieldTarget.addEventListener('input', (e) => {
      controller.formatBusinessRegistrationNumber(e.target.value);
    });
  }

  onRegistrationRequiredChange(event) {
    if (event.target.value === 'false') {
      // NO
      this.businessInfoFormTarget.style.display = 'none';
      this.dontWorryDivTarget.style.display = 'block';
    } else {
      // YES
      this.businessInfoFormTarget.style.display = 'block';
      this.dontWorryDivTarget.style.display = 'none';
    }

    this.fixRequiredFields();
  }

  onCompanyTypeChange(event) {
    if ((event.target.value === '0') || (event.target.value === '1')) {
      // COMPANY_TYPE_PRIVATE || COMPANY_TYPE_PUBLIC
      this.nonprofitFormTarget.style.display = 'none';
      this.publicPrivateFormTarget.style.display = 'block';

      if (this.selectBusinessTypeTarget.value === '0') {
        // BUSINESS_TYPE_SOLE_PROPRIETOR
        this.businessNameFormTarget.style.display = 'none';
      }
    } else if (event.target.value === '2') {
      // COMPANY_TYPE_NON_PROFIT
      this.nonprofitFormTarget.style.display = 'block';
      this.publicPrivateFormTarget.style.display = 'none';
      this.businessNameFormTarget.style.display = 'block';
    } else {
      // COMPANY_TYPE_GOVERNMENT
      this.nonprofitFormTarget.style.display = 'none';
      this.publicPrivateFormTarget.style.display = 'none';
      this.businessNameFormTarget.style.display = 'block';
    }

    if (event.target.value === '1') {
      // COMPANY_TYPE_PUBLIC
      this.publicFormTarget.style.display = 'block';
    } else {
      this.publicFormTarget.style.display = 'none';
    }

    this.fixRequiredFields();
  }

  onBusinessTypeChange(event) {
    if (event.target.value === '0') {
      // BUSINESS_TYPE_SOLE_PROPRIETOR
      this.soleProprietorWarningDivTarget.style.display = 'block';
      this.businessNameFormTarget.style.display = 'none';
    } else {
      this.soleProprietorWarningDivTarget.style.display = 'none';
      this.businessNameFormTarget.style.display = 'block';
    }

    this.fixRequiredFields();
  }

  onBusinessRegistrationIdentifierChange() {
    this.businessRegistrationIdentifier = this.businessRegistrationIdentifierFieldTarget.value;
    this.businessRegistrationNumberFieldTarget.value = '';
  }

  formatBusinessRegistrationNumber(value) {
    if (this.businessRegistrationIdentifier === 'EIN') {
      // BUSINESS_ID_EIN
      this.businessRegistrationNumberFieldTarget.value = formatGeneral(value, {
        blocks: [2, 7],
        delimiters: ['-'],
        delimiterLazyShow: true,
        numericOnly: true,
      });
    } else if (this.businessRegistrationIdentifier === 'CBN') {
      // BUSINESS_ID_CBN
      this.businessRegistrationNumberFieldTarget.value = formatGeneral(value, {
        blocks: [9],
        numericOnly: true,
      });
    }
  }

  // With browser validation enabled, any input marked as required will prevent the form from submitting (including inputs that are not visible). To keep this controller simple, manually set the disabled property of any required input that is hidden so that it is excluded from validation.
  fixRequiredFields() {
    this.element.querySelectorAll('input[required]').forEach((el) => {
      // If any of the parents of the input have the display property set to 'none' then it's offsetParent will be null
      const input = el;
      input.disabled = (input.offsetParent === null);
    });
  }
}
