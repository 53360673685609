import { Controller } from '@hotwired/stimulus';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static targets = ['autocomplete'];

  connect() {
    this.templates = JSON.parse(this.data.get('templates'));

    // Remember what the country is initially set to, it should be the same as team.org_country
    this.defaultCountry = this.element.querySelector('select[name="job_person[country]"]').value;

    this.autocompleteTarget.addEventListener('autocomplete.change', (event) => {
      this.populateFields(event);
    });
  }

  populateFields(event) {
    const searchResult = JSON.parse(event.detail.value);

    this.element.querySelector('input[name="job_person[first_name]"]').value = searchResult.first_name;
    this.element.querySelector('input[name="job_person[last_name]"]').value = searchResult.last_name;
    this.element.querySelector('input[name="job_person[org]"]').value = searchResult.org;
    this.element.querySelector('input[name="job_person[street]"]').value = searchResult.street;
    this.element.querySelector('input[name="job_person[street2]"]').value = searchResult.street2;
    this.element.querySelector('input[name="job_person[city]"]').value = searchResult.city;
    this.element.querySelector('input[name="job_person[state]"]').value = searchResult.state;
    this.element.querySelector('input[name="job_person[zip]"]').value = searchResult.zip;
    this.element.querySelector('select[name="job_person[country]"]').value = searchResult.country;

    this.populateEmails(searchResult.emails);
    this.populatePhones(searchResult.phones);
    this.populateHiddenInput('person_id', searchResult.uuid);
    this.populateHiddenInput('source', 1); // PERSON_SOURCE_TI_ADDRESS_BOOK
  }

  populateEmails(emailArray) {
    const container = this.element.querySelector('#email-list');
    const template = this.templates.email;

    // Remove any existing entries
    container.innerHTML = '';

    if ((emailArray == null) || (emailArray.length === 0)) {
      // Show an empty line
      container.insertAdjacentHTML('beforeend', template.replace(/FORM_INDEX_TO_REPLACE/g, uuidv4()));
    } else {
      emailArray.forEach((emailItem) => {
        const nextIndex = uuidv4();
        container.insertAdjacentHTML('beforeend', template.replace(/FORM_INDEX_TO_REPLACE/g, nextIndex));
        this.element.querySelector(`input[name="person_emails[${nextIndex}][value]"]`).value = emailItem.value;
      });
    }
  }

  populatePhones(phoneArray) {
    const container = this.element.querySelector('#phone-list');
    const template = this.templates.phone;

    // Remove any existing entries
    container.innerHTML = '';

    if ((phoneArray == null) || (phoneArray.length === 0)) {
      // Show an empty line
      container.insertAdjacentHTML('beforeend', template.replace(/FORM_INDEX_TO_REPLACE/g, uuidv4()));
    } else {
      phoneArray.forEach((phoneItem) => {
        const nextIndex = uuidv4();
        container.insertAdjacentHTML('beforeend', template.replace(/FORM_INDEX_TO_REPLACE/g, nextIndex));
        this.element.querySelector(`input[name="person_phones[${nextIndex}][value]"]`).value = phoneItem.value;
        this.element.querySelector(`input[name="person_phones[${nextIndex}][label]"]`).value = phoneItem.label;
      });
    }
  }

  populateHiddenInput(name, value) {
    // If the hidden field already exists, just change the value
    let input = this.element.querySelector(`input[name="job_person[${name}]"]`);

    if (!(input == null)) {
      input.value = value;
      return;
    }

    // Otherwise create a new one
    input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', `job_person[${name}]`);
    input.setAttribute('value', value);

    this.element.appendChild(input);
  }

  removeHiddenInput(name) {
    const input = this.element.querySelector(`input[name="job_person[${name}]"]`);

    if (!(input == null)) {
      input.parentNode.removeChild(input);
    }
  }

  onCancel() {
    const myModalEl = document.getElementById('addPersonModal');
    const modal = Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
    modal.hide();

    this.element.querySelector('input[name="contact_search[query]"]').value = '';
    this.element.querySelector('input[name="job_person[first_name]"]').value = '';
    this.element.querySelector('input[name="job_person[last_name]"]').value = '';
    this.element.querySelector('input[name="job_person[org]"]').value = '';
    this.element.querySelector('input[name="job_person[street]"]').value = '';
    this.element.querySelector('input[name="job_person[street2]"]').value = '';
    this.element.querySelector('input[name="job_person[city]"]').value = '';
    this.element.querySelector('input[name="job_person[state]"]').value = '';
    this.element.querySelector('input[name="job_person[zip]"]').value = '';
    this.element.querySelector('select[name="job_person[country]"]').value = this.defaultCountry;
    this.populateEmails(null);
    this.populatePhones(null);
    this.removeHiddenInput('person_id');
    this.removeHiddenInput('source');
  }
}
