// Thanks https://stackoverflow.com/a/54534797/406797
import { Controller } from '@hotwired/stimulus';
import utils from 'Common/utils';

export default class extends Controller {
  // It's expected that the input control itself is the dom element with the controller reference (i.e. "data: { controller: 'currency-input' }")
  connect() {
    const controller = this;
    this.currency = this.data.get('currency') || 'USD'; // https://www.currency-iso.org/dam/downloads/lists/list_one.xml

    this.element.addEventListener('focus', (e) => { controller.onFocus(e.target); });
    this.element.addEventListener('blur', (e) => { controller.onBlur(e.target); });

    this.onBlur(this.element);
  }

  onFocus(target) {
    const inputTarget = target;
    const { value } = inputTarget;
    inputTarget.value = value ? utils.localStringToNumber(value) : '';
  }

  onBlur(target) {
    const inputTarget = target;
    const value = inputTarget.value || 0;

    const options = {
      maximumFractionDigits: 2,
      currency: this.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
    };

    inputTarget.value = value
      ? utils.localStringToNumber(value).toLocaleString(undefined, options)
      : '';
  }
}
