import { Controller } from '@hotwired/stimulus';
import SignaturePad from 'signature_pad';

export default class SignatureFormController extends Controller {
  static targets = ['signaturePadCanvas', 'signatureData', 'requiredFormItem', 'submitButton'];

  initialize() {
    this.signaturePad = null;
  }

  connect() {
    const canvas = this.signaturePadCanvasTarget;

    if (canvas == null) return;

    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'rgb(255, 255, 255, 0)', // last zero gives transparency!!
    });

    this.signaturePad.addEventListener('endStroke', () => {
        this.validateRequiredFormItems();
    });

    this.requiredFormItemTargets.forEach((target) => {
      target.addEventListener('click', () => this.validateRequiredFormItems());
    });
  }

  disconnect() {
    this.signaturePad = null;
  }

  validateRequiredFormItems() {
    let allRequirementsMet = this.signaturePresent();

    this.requiredFormItemTargets.forEach((target) => {
      if (target.checked === false) allRequirementsMet = false;
    });

    this.submitButtonTarget.disabled = !allRequirementsMet;
  }

  signaturePresent() {
    return !this.signaturePad.isEmpty() || (this.signatureDataTarget.value.length > 0);
  }

  embedSignatureDataInForm() {
    // Tests will directly put fake signature data into the signature data target
    if (this.signatureDataTarget.value.length > 0) return;

    this.setImageToInput();
  }

  clear() {
    this.signaturePad.clear();
    this.submitButtonTarget.disabled = true;
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop();
      // remove the last dot or line
      this.signaturePad.fromData(data);
    }

    this.validateRequiredFormItems();
  }

  // Extract the signature data from the canvas and put it into the signature data target hidden form item
  setImageToInput() {
    const canvas = this.signaturePadCanvasTarget;
    const ctx = canvas.getContext('2d');
    ctx.globalAlpha = 0.2;

    const dataURL = this.signaturePad.toDataURL('image/png');
    this.signatureDataTarget.value = dataURL;
  }
}
