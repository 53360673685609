import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkAllBox', 'checkbox', 'multiSelectionControlGroup'];

  connect() {
    this.somethingSelected = false;
  }

  isAnythingSelected() {
    this.somethingSelected = false;
    let somethingNotSelected = false;

    this.checkboxTargets.forEach((target) => {
      const eachCheckbox = target;
      if (eachCheckbox.checked) {
        this.somethingSelected = true;
      } else {
        somethingNotSelected = true;
      }
    });

    // If something is not selected, make sure the checkAllBox is cleared
    if (somethingNotSelected) {
      this.checkAllBoxTarget.checked = false;
    }
  }

  updateMultiSelectionControlGroup() {
    if (this.somethingSelected) {
      this.multiSelectionControlGroupTarget.style.display = 'block';
    } else {
      this.multiSelectionControlGroupTarget.style.display = 'none';
    }
  }

  // UI responders

  onCheckAllBox() {
    const shouldCheck = this.checkAllBoxTarget.checked;

    this.checkboxTargets.forEach((target) => {
      const eachCheckbox = target;
      eachCheckbox.checked = shouldCheck;
    });
    this.somethingSelected = shouldCheck;
    this.updateMultiSelectionControlGroup();
  }

  onCheckbox() {
    this.isAnythingSelected();
    this.updateMultiSelectionControlGroup();
  }
}
