import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];

  static values = {
    attribute: String,
    hashTable: Object,
  };

  setFromInput({ target: { value } }) {
    const dynamicAttributeValue = this.hashTableValue[value];
    this.elementTarget.setAttribute(this.attributeValue,
      dynamicAttributeValue);
  }
}
