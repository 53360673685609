import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      dateFormat: 'm-d-Y',
      altInput: true,
      altInputClass: 'form-control flatpickr-input-override',
      altFormat: 'F j, Y',
      disableMobile: true, // The built-in mobile processing makes the control unusable on iOS Safari, hopefully that gets fixed in the future
    });
  }
}
