import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import utils from 'Common/utils';

export default class extends Controller {
  static targets = ['container', 'startDate', 'endDate'];

  connect() {
    // Get the initial data right now
    this.refresh();
  }

  refresh() {
    const startDate = this.startDateTarget.value;
    const endDate = this.endDateTarget.value;

    if (!utils.dateStringIsValid(startDate)) {
      this.startDateTarget.setCustomValidity('Invalid date');
      return;
    }

    if (!utils.dateStringIsValid(endDate)) {
      this.endDateTarget.setCustomValidity('Invalid date');
      return;
    }

    this.startDateTarget.setCustomValidity('');
    this.endDateTarget.setCustomValidity('');

    this.loadData(startDate, endDate);
  }

  loadData(startDate, endDate) {
    const controller = this;

    $.ajax({
      type: 'GET',
      url: controller.data.get('url'),
      dataType: 'html',
      data: {
        start: startDate,
        end: endDate,
      },
      success(data) {
        controller.containerTarget.innerHTML = data;
      },
    });
  }
}
