import { Controller } from '@hotwired/stimulus';
import { GoogleCharts } from 'google-charts';

export default class extends Controller {
  connect() {
    this.initCharts();
  }

  drawOverviewCharts() {
    const charts = JSON.parse(this.data.get('charts'));

    charts.forEach((chart) => {
      this.drawChart(chart.data_table, chart.key);
    });
  }

  drawChart(dataTable, divID) {
    const data = GoogleCharts.api.visualization.arrayToDataTable(dataTable);

    const options = {
      pieHole: 0.4,
      legend: 'none',
      chartArea: {
        top: 10,
        bottom: 10,
        width: '100%',
        height: '100%',
      },
      colors: [
        '#004dd0',
        '#0078ea',
        '#000c9a',
      ],
    };

    const chart = new GoogleCharts.api.visualization.PieChart(document.getElementById(divID));
    chart.draw(data, options);
  }

  initCharts() {
    GoogleCharts.load(this.drawOverviewCharts.bind(this));
  }
}
