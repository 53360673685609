import { Controller } from '@hotwired/stimulus';
import { v4 as uuidv4 } from 'uuid';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.settingsHash = JSON.parse(this.data.get('settings'));
  }

  onDuplicateButton() {
    const newElement = this.settingsHash.template.replace(/FORM_INDEX_TO_REPLACE/g, uuidv4());
    this.containerTarget.insertAdjacentHTML('beforeend', newElement);
  }
}
