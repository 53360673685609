import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';

export default class extends Controller {
  static targets = ['alert', 'checkbox', 'submitButton'];

  static values = { url: String };

  selectedContactIds() {
    const contactCheckboxes = document.querySelectorAll('[data-multiple-select-form-target="checkbox"]');
    return Array.from(contactCheckboxes).filter((checkboxElement) => checkboxElement.checked).map((checkboxElement) => checkboxElement.value);
  }

  selectedRoleUuids() {
    return this.checkboxTargets.filter((checkboxElement) => checkboxElement.checked).map((checkboxElement) => checkboxElement.value);
  }

  isAnythingSelected() {
    this.somethingSelected = (this.checkboxTargets.filter((checkboxElement) => checkboxElement.checked).length > 0);
  }

  updateSubmitButton() {
    if (this.somethingSelected) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  // UI responders

  onCheckbox() {
    this.isAnythingSelected();
    this.updateSubmitButton();
  }

  async onSubmit() {
    this.submitButtonTarget.disabled = true;
    this.alertTarget.style.display = 'none';

    const response = await put(
      this.urlValue,
      {
        body: {
          contact_ids: this.selectedContactIds(),
          role_uuids: this.selectedRoleUuids(),
        },
      },
    );

    if (response.ok) {
      // TODO: remove this if we use turbo streams for the larger view
      location.reload(); // eslint-disable-line no-restricted-globals
    } else {
      this.alertTarget.style.display = 'block';
    }

    this.submitButtonTarget.disabled = false;
  }
}
