import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const googleEvent = this.data.get('event');

    this.element.addEventListener('click', () => {
      ga('send', 'event', 'button', 'click', googleEvent); // eslint-disable-line no-undef
    });
  }
}
