import { Controller } from '@hotwired/stimulus';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxon2Plugin, { toLuxonDateTime } from '@fullcalendar/luxon2';
import { DateTime } from 'luxon';
import $ from 'jquery';
import SessionHelper from './session_helper';

class MonthCalendarController extends Controller {
  static targets = ['legendCheckbox', 'calendarTitleMonth', 'calendarTitleEtc'];

  connect() {
    const startDate = this.data.get('startdate');

    if (startDate) {
      this.selectedDay = DateTime.fromISO(startDate, { zone: this.data.get('timezone') }).toJSDate();
    } else {
      this.selectedDay = this.today();
    }

    this.initCalendar();
    this.updateSelectedDayJobs(true, false);
    this.startRefreshing();
  }

  disconnect() {
    this.stopRefreshing();
  }

  today() {
    return DateTime.local().setZone(this.data.get('timezone')).startOf('day').toJSDate();
  }

  initCalendar() {
    const calendarEl = document.getElementById('calendar-container');
    const controller = this;

    // In some circumstances the calendar does a poor job of clearing itself and there can be duplicate instances on the page when using the browser back button. Solve this by clearing anything inside the calendar container before rendering a new calendar.
    calendarEl.innerHTML = '';

    this.calendar = new Calendar(calendarEl, {
      plugins: [luxon2Plugin, dayGridPlugin, interactionPlugin],
      timeZone: this.data.get('timezone'),
      initialDate: this.selectedDay,
      height: 700,
      dateClick(info) {
        controller.onClickDay(info.dayEl, info.date);
      },
      dayCellDidMount(dayRenderInfo) {
        controller.attachHoverHandler(dayRenderInfo);
      },
      eventDisplay: 'block',
      eventSources: [
        {
          id: 'jobs',
          events(info, successCallback) {
            controller.getJobs(info, successCallback);
          },
        },
        {
          id: 'conflicts',
          events(info, successCallback) {
            controller.getConflicts(info, successCallback);
          },
        },
      ],
      headerToolbar: false,
      loading(bool) {
        controller.showLoadingSpinner(bool);
      },
    });

    this.calendar.render();
    this.updateTitle();
  }

  currentCalendarDate() {
    return toLuxonDateTime(this.calendar.getDate(), this.calendar);
  }

  updateTitle() {
    const date = this.currentCalendarDate();

    this.calendarTitleMonthTargets.forEach((target) => {
      const titleTarget = target;
      titleTarget.innerHTML = date.toFormat('MMMM');
    });

    this.calendarTitleEtcTargets.forEach((target) => {
      const titleTarget = target;
      titleTarget.innerHTML = date.toFormat('y');
    });
  }

  attachHoverHandler(dayRenderInfo) {
    const element = dayRenderInfo.el;
    const cellDate = dayRenderInfo.date;
    const controller = this;

    if (controller.isSelectedDay(cellDate)) {
      element.style.backgroundColor = MonthCalendarController.cellBackgroundColorSelected;
      controller.lastSelectedDayCell = element;
    } else {
      element.style.backgroundColor = MonthCalendarController.cellBackgroundColor;
    }

    element.addEventListener('mouseenter', () => {
      if (controller.isSelectedDay(cellDate)) {
        element.style.backgroundColor = MonthCalendarController.cellBackgroundColorSelectedHover;
      } else {
        element.style.backgroundColor = MonthCalendarController.cellBackgroundColorHover;
      }
    });

    element.addEventListener('mouseleave', () => {
      if (controller.isSelectedDay(cellDate)) {
        element.style.backgroundColor = MonthCalendarController.cellBackgroundColorSelected;
      } else {
        element.style.backgroundColor = MonthCalendarController.cellBackgroundColor;
      }
    });
  }

  getJobs(info, successCallback) {
    $.ajax({
      type: 'GET',
      url: this.data.get('jobsurl'),
      dataType: 'json',
      data: {
        start: info.start.toISOString(),
        end: info.end.toISOString(),
        hide_users: this.hiddenUsersArray(),
      },
      success(data) {
        successCallback(data.jobs);
      },
    });
  }

  getConflicts(info, successCallback) {
    $.ajax({
      type: 'GET',
      url: this.data.get('conflictsurl'),
      dataType: 'json',
      data: {
        start: info.start.toISOString(),
        end: info.end.toISOString(),
        hide_users: this.hiddenUsersArray(),
      },
      success(data) {
        successCallback(data.conflicts);
      },
    });
  }

  onClickDay(element, date) {
    const dayElement = element;
    this.lastSelectedDayCell.style.backgroundColor = MonthCalendarController.cellBackgroundColor;
    dayElement.style.backgroundColor = MonthCalendarController.cellBackgroundColorSelected;
    this.lastSelectedDayCell = dayElement;
    this.selectedDay = date;
    this.updateSelectedDayJobs(true, true);
  }

  updateSelectedDayJobs(clearDuringUpdate, saveSession) {
    const controller = this;
    const start = new Date(this.selectedDay.getTime());
    const end = new Date(this.selectedDay.getTime());
    end.setUTCDate(end.getUTCDate() + 1);

    if (clearDuringUpdate) {
      document.body.querySelector('#selected-day-container').innerHTML = '';
      this.showSelectedDaySpinner(true);
    }

    $.ajax({
      type: 'GET',
      url: this.data.get('dayurl'),
      dataType: 'html',
      data: {
        start: start.toISOString(),
        end: end.toISOString(),
        hide_users: this.hiddenUsersArray(),
      },
      success(data) {
        document.body.querySelector('#selected-day-container').innerHTML = data;

        if (saveSession) {
          // The selected calendar data is saved to the rails session synchronously to avoid a race condition with cookies
          controller.saveSelectedCalendarDate();
        }
      },
    }).done(() => {
      controller.showSelectedDaySpinner(false);
    });
  }

  isSelectedDay(date) {
    return this.sameDay(this.selectedDay, date);
  }

  sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear()
           && d1.getMonth() === d2.getMonth()
           && d1.getDate() === d2.getDate();
  }

  showSelectedDaySpinner(shouldShow) {
    const spinner = document.body.querySelector('#selected-day-spinner');

    if (shouldShow) {
      spinner.style.display = 'block';
    } else {
      spinner.style.display = 'none';
    }
  }

  showLoadingSpinner(shouldShow) {
    const spinner = document.body.querySelector('#loading-spinner');

    if (spinner == null) {
      return;
    }

    if (shouldShow) {
      spinner.style.visibility = 'visible';
    } else {
      spinner.style.visibility = 'hidden';
    }
  }

  toggleLegendCheckbox() {
    this.refreshCalendar();
  }

  hiddenUsersArray() {
    const checkedUserIds = [];

    this.legendCheckboxTargets.forEach((target) => {
      if (!target.checked) {
        checkedUserIds.push(target.value);
      }
    });

    return checkedUserIds;
  }

  refreshCalendar() {
    this.calendar.refetchEvents();
    this.updateSelectedDayJobs(false, false);
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.refreshCalendar();
    }, MonthCalendarController.refreshInterval * 1000);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  onTodayButton() {
    this.calendar.today();
    this.updateTitle();
    this.onClickDay(document.body.querySelector('.fc-day-today'), this.today());
  }

  onPrevButton() {
    this.calendar.prev();
    this.updateTitle();
  }

  onNextButton() {
    this.calendar.next();
    this.updateTitle();
  }

  saveSelectedCalendarDate() {
    const value = DateTime.fromJSDate(this.selectedDay);
    SessionHelper.setParam('manage_jobs', 'selected_calendar_date', value.toISODate());
  }
}

MonthCalendarController.cellBackgroundColor = '';
MonthCalendarController.cellBackgroundColorHover = '#F5F7F9';
MonthCalendarController.cellBackgroundColorSelected = '#e5e6e7';
MonthCalendarController.cellBackgroundColorSelectedHover = '#d5d6d7';
MonthCalendarController.refreshInterval = (5 * 60); // 5 minutes

export default MonthCalendarController;
