import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // Bootstrap modal definitions need to be at the top level of the DOM in order to render properly. If a modal definition is being loaded asynchronously in Ajax, it's not possible to put the definition in the right place in the DOM. This method gets around the problem by forcibly moving the modal to the right place in the DOM once the content is loaded.
    const contents = this.element.innerHTML;
    this.element.innerHTML = '';
    document.body.insertAdjacentHTML('beforeend', contents);
  }
}
