import { Controller } from '@hotwired/stimulus';
import Cropper from 'cropperjs';
import { Modal, Tooltip } from 'bootstrap';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    this.token = (document.querySelector('[name=csrf-token]') || {}).content || '';
    this.initCropper();
  }

  initCropper() {
    const avatar = document.getElementById('existing_profile_image');
    const image = document.getElementById('image');
    const input = document.getElementById('profile-file-picker');
    const $progress = $('.progress');
    const $progressBar = $('.progress-bar');
    const $alert = $('.alert');
    const modalEl = document.getElementById('cropper-modal');
    const modal = Modal.getOrCreateInstance(modalEl); // Returns a Bootstrap modal instance

    let cropper;
    const postUrl = this.data.get('url');
    const controller = this;

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new Tooltip(tooltipTriggerEl);
    });

    input.addEventListener('change', (e) => {
      const { files } = e.target;
      const done = function done(url) {
        input.value = '';
        image.src = url;
        $alert.hide();
        modal.show();
      };
      let reader;
      if (files && files.length > 0) {
        const [file] = files;
        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = function onload() {
            done(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    });

    modalEl.addEventListener('shown.bs.modal', () => {
      cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 1,
      });
    });
    modalEl.addEventListener('hidden.bs.modal', () => {
      cropper.destroy();
      cropper = null;
    });

    document.getElementById('crop').addEventListener('click', () => {
      let initialAvatarURL;
      let canvas;

      modal.hide();

      if (cropper) {
        canvas = cropper.getCroppedCanvas({
          width: 320,
          height: 320,
        });
        controller.showPlaceholder(false);
        initialAvatarURL = avatar.src;
        avatar.src = canvas.toDataURL();
        $progress.show();
        $alert.removeClass('alert-success alert-warning');
        canvas.toBlob((blob) => {
          const formData = new FormData();
          formData.append('profile_image', blob, 'avatar.jpg');
          formData.append('authenticity_token', this.token);
          $.ajax(postUrl, {
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            xhr() {
              const xhr = new XMLHttpRequest();
              xhr.upload.onprogress = function onprogress(e) {
                let percent = '0';
                let percentage = '0%';
                if (e.lengthComputable) {
                  percent = Math.round((e.loaded / e.total) * 100);
                  percentage = `${percent}%`;
                  $progressBar.width(percentage).attr('aria-valuenow', percent).text(percentage);
                }
              };
              return xhr;
            },
            success() {
              $alert.show().addClass('alert-success').text('Upload success');
            },
            error() {
              avatar.src = initialAvatarURL;
              controller.showPlaceholder(true);
              $alert.show().addClass('alert-warning').text('Upload error');
            },
            complete() {
              $progress.hide();
            },
          });
        });
      }
    });
  }

  showPlaceholder(shouldShow) {
    const placeholder = document.body.querySelector('.placeholder_profile_image_frame #profile-placeholder');

    if (placeholder == null) {
      return;
    }

    if (shouldShow) {
      placeholder.style.display = 'block';
    } else {
      placeholder.style.display = 'none';
    }
  }
}
