import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import $ from 'jquery';
import utils from 'Common/utils';

export default class extends Controller {
  connect() {
    // Get the initial data right now
    this.refresh();
  }

  refresh() {
    const startDate = document.getElementById('end_date').value;
    const weekCount = document.getElementById('week_count').value;
    const subFilter = document.getElementById('subscription_filter').value;
    const showDelta = document.getElementById('show_delta_percentage').value;

    if (utils.dateStringIsValid(startDate)) {
      this.loadData(startDate, weekCount, subFilter, showDelta);
    } else {
      const myModalEl = document.getElementById('date-picker-error');
      const modal = Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
      modal.show();
    }
  }

  loadData(startDate, weekCount, subFilter, showDelta) {
    $.ajax({
      type: 'GET',
      url: '/admin/dashboard/locations_data',
      dataType: 'html',
      data: {
        start: startDate,
        week_count: weekCount,
        subscription_filter: subFilter,
        show_delta_percentage: showDelta,
      },
      success(data) {
        $('.locations-container').html(data);
      },
    });
  }
}
