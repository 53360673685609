import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sidebar', 'accent'];

  connect() {
    const sidebarColorInput = this.element.querySelector('input[name="team[brand_color_1]"]');
    sidebarColorInput.addEventListener('change', (event) => this.onSidebarChange(event));

    const accentColorInput = this.element.querySelector('input[name="team[brand_color_2]"]');
    accentColorInput.addEventListener('change', (event) => this.onAccentChange(event));
  }

  onSidebarChange(event) {
    this.sidebarTarget.style.backgroundColor = event.currentTarget.value;
  }

  onAccentChange(event) {
    this.accentTarget.style.backgroundColor = event.currentTarget.value;
  }
}
