import { Controller } from '@hotwired/stimulus';
import { Tooltip } from 'bootstrap';

// From https://guides.rubyonrails.org/active_storage_overview.html#direct-uploads
export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.element.addEventListener('direct-upload:initialize', (event) => {
      const { target, detail } = event;
      const { id, file } = detail;
      target.insertAdjacentHTML('beforebegin', `
        <div id="direct-upload-${id}" class="direct-upload direct-upload--pending" data-bs-toggle='tooltip' data-bs-placement='top'>
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename"></span>
        </div>
      `);
      target.previousElementSibling.querySelector('.direct-upload__filename').textContent = file.name;
    });

    this.element.addEventListener('direct-upload:start', (event) => {
      const { id } = event.detail;
      const element = document.getElementById(`direct-upload-${id}`);
      element.classList.remove('direct-upload--pending');

      this.inputTarget.style.display = 'none';
    });

    this.element.addEventListener('direct-upload:progress', (event) => {
      const { id, progress } = event.detail;
      const progressElement = document.getElementById(`direct-upload-progress-${id}`);
      progressElement.style.width = `${progress}%`;
    });

    this.element.addEventListener('direct-upload:error', (event) => {
      event.preventDefault();
      const { id, error } = event.detail;
      const element = document.getElementById(`direct-upload-${id}`);
      element.classList.add('direct-upload--error');
      element.setAttribute('title', error);

      // This has to be done after the title attribute is set
      this.initializeTooltips();
      this.inputTarget.style.display = 'block';
    });

    this.element.addEventListener('direct-upload:end', (event) => {
      const { id } = event.detail;
      const element = document.getElementById(`direct-upload-${id}`);
      element.classList.add('direct-upload--complete');
    });
  }

  // This can be called as an outlet from another controller on the page to notify this controller to reset its UI
  reset() {
    this.inputTarget.style.display = 'block';
    this.inputTarget.disabled = false;
    this.inputTarget.value = null;
    document.querySelectorAll('.direct-upload').forEach((directUploadElement) => directUploadElement.remove());
  }

  // Use bootstrap tooltips to make it obvious that there is an issue when you hover
  initializeTooltips() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new Tooltip(tooltipTriggerEl);
    });
  }
}
