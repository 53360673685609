import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['placeholder', 'list'];

  connect() {
    this.loading = false;
    this.batchSize = parseInt(this.data.get('batchSize'), 10);
    this.reportCount = parseInt(this.data.get('reportCount'), 10);
    this.offset = this.batchSize;
    this.currentPage = 0;
    this.showingAllResults = true;

    if (this.reportCount > this.batchSize) {
      this.showingAllResults = false;
    }

    let anchorValue;
    const strippedUrl = document.location.toString().split('#');

    if (strippedUrl.length > 1) {
      anchorValue = Number(strippedUrl[1]);

      if (!Number.isNaN(anchorValue)) {
        if (anchorValue > 0) {
          // when the page first loads, if there is already an anchor value on the URL than the user probably hit the back button from a report detail page. Attempt to retrieve the remaining data and scrolls the window down to the general area the user was in before hitting back, to make it slightly less painful to scroll all the way down again.
          document.location.hash = 0;
          this.loadNextBlock(anchorValue);
        }
      }
    }

    document.addEventListener('scroll', this.scrollHandler);
  }

  disconnect() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler = () => {
    // when the window scrolls, figure out when the user is scroll to the very bottom of the page
    if ($(window).scrollTop() + $(window).height() === this.getDocHeight()) {
      if (!this.loading && !this.showingAllResults) {
        this.loadNextBlock(1);
      }
    }

    if ($(window).scrollTop() === 0) {
      this.currentPage = 0;
      document.location.hash = '0';
    }
  };

  getDocHeight() {
    const D = document;
    return Math.max(
      Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
      Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
      Math.max(D.body.clientHeight, D.documentElement.clientHeight),
    );
  }

  loadNextBlock(blockCount) {
    // show the loading indicator
    this.loading = true;
    this.showPlaceholder(true);
    const controller = this;

    // make an asynchronous call to get the next block of reports to display
    $.ajax({
      type: 'GET',
      url: this.data.get('url'),
      data: {
        offset: controller.offset,
        count: controller.blockCount,
        current_page: controller.currentPage,
      },
      dataType: 'html',
    }).always(() => {
      controller.loading = false;
      controller.showPlaceholder(false);
    }).done((data) => {
      // assume that the maximum number of records are retrieved, even though it may have been less. When that happens it's the end of the list anyway.
      controller.offset += controller.batchSize;
      if (controller.offset >= controller.reportCount) {
        // That's everything, so disable infinite scrolling
        controller.showingAllResults = true;
      }

      controller.currentPage += blockCount;
      this.listTarget.insertAdjacentHTML('beforeend', data);
      document.location.hash = controller.currentPage;
    });
  }

  showPlaceholder(shouldShow) {
    if (shouldShow) {
      this.placeholderTarget.style.display = 'block';
    } else {
      this.placeholderTarget.style.display = 'none';
    }
  }
}
