import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'inspectorOptions',
    'dateOptions',
    'jobStateOptions',
  ];

  onFilterByInspectorChange(event) {
    if (event.target.value === '1') {
      this.inspectorOptionsTarget.style.display = 'block';
    } else {
      this.inspectorOptionsTarget.style.display = 'none';
    }
  }

  onFilterByDateChange(event) {
    if (event.target.value === '1') {
      this.dateOptionsTarget.style.display = 'block';
    } else {
      this.dateOptionsTarget.style.display = 'none';
    }
  }

  onFilterByJobStateChange(event) {
    if (event.target.value === '1') {
      this.jobStateOptionsTarget.style.display = 'block';
    } else {
      this.jobStateOptionsTarget.style.display = 'none';
    }
  }
}
