/* eslint no-console:0 */
import 'bootstrap';
import '@hotwired/turbo-rails';
import Bugsnag from '@bugsnag/js';

// Initialize Stimulus

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

import * as ActiveStorage from '@rails/activestorage';

// Add a notification bar for users with really old browsers.
// Auto generate the configuration options here: https://browser-update.org/

import browserUpdate from 'browser-update';
import Trix from 'trix'; // eslint-disable-line no-unused-vars

// Initialize stylesheets

import 'styles/application_pack.scss';

// Enable support for direct uploads
ActiveStorage.start();

Bugsnag.start('a4bfbdb69ba940da4bc89b00dbc7d206');

window.Stimulus = Application.start();
const context = require.context('stimulus/application', true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));

browserUpdate({
  required: {
    e: -4,
    f: -3,
    o: -3,
    s: -1,
    c: -3,
  },
  insecure: true,
});
