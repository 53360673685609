import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source'];

  copy(event) {
    const { target } = event;
    this.sourceTarget.select();
    document.execCommand('copy');
    target.innerText = 'Copied!';
  }
}
