import { Controller } from '@hotwired/stimulus';
import { GoogleCharts } from 'google-charts';

export default class extends Controller {
  connect() {
    this.initCharts();
  }

  drawOverviewCharts() {
    const charts = JSON.parse(this.data.get('charts'));

    charts.forEach((chart) => {
      this.drawChart(chart.title, chart.data_table, chart.key);
    });
  }

  drawChart(title, dataTable, divID) {
    const data = GoogleCharts.api.visualization.arrayToDataTable(dataTable);

    const options = {
      height: 450,
      title,
      legend: { position: 'top', alignment: 'center' },
      isStacked: true,
    };

    const chart = new GoogleCharts.api.visualization.SteppedAreaChart(document.getElementById(divID));
    chart.draw(data, options);
  }

  initCharts() {
    GoogleCharts.load(this.drawOverviewCharts.bind(this));
  }
}
