import { library, dom } from '@fortawesome/fontawesome-svg-core';

import { faCircleCheck as faCircleCheckDuotone } from '@fortawesome/pro-duotone-svg-icons/faCircleCheck';
import { faCircleUser } from '@fortawesome/pro-duotone-svg-icons/faCircleUser';

import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faBoxArchive } from '@fortawesome/pro-solid-svg-icons/faBoxArchive';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCamera } from '@fortawesome/pro-solid-svg-icons/faCamera';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons/faSquareCheck';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faEnvelope as faEnvelopeS } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons/faEllipsis';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { faFileArrowDown } from '@fortawesome/pro-solid-svg-icons/faFileArrowDown';
import { faFlagCheckered } from '@fortawesome/pro-solid-svg-icons/faFlagCheckered';
import { faGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons/faGripVertical';
import { faHouse as faHouseS } from '@fortawesome/pro-solid-svg-icons/faHouse';
import { faHeartCrack } from '@fortawesome/pro-solid-svg-icons/faHeartCrack';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPlug } from '@fortawesome/pro-solid-svg-icons/faPlug';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons/faShieldCheck';
import { faRightToBracket } from '@fortawesome/pro-solid-svg-icons/faRightToBracket';
import { faSkullCrossbones } from '@fortawesome/pro-solid-svg-icons/faSkullCrossbones';
import { faListCheck as faListCheckS } from '@fortawesome/pro-solid-svg-icons/faListCheck';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faTruckMoving } from '@fortawesome/pro-solid-svg-icons/faTruckMoving';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint';

import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';
import { faCalendarExclamation } from '@fortawesome/pro-regular-svg-icons/faCalendarExclamation';
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons/faCalendarTimes';
import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons/faCalendarWeek';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faClone } from '@fortawesome/pro-regular-svg-icons/faClone';
import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons/faCloudArrowUp';
import { faDesktop } from '@fortawesome/pro-regular-svg-icons/faDesktop';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faFileSignature } from '@fortawesome/pro-regular-svg-icons/faFileSignature';
import { faFaceFrownOpen } from '@fortawesome/pro-regular-svg-icons/faFaceFrownOpen';
import { faHouse } from '@fortawesome/pro-regular-svg-icons/faHouse';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faList } from '@fortawesome/pro-regular-svg-icons/faList';
import { faMobile } from '@fortawesome/pro-regular-svg-icons/faMobile';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faTablet } from '@fortawesome/pro-regular-svg-icons/faTablet';
import { faGauge } from '@fortawesome/pro-regular-svg-icons/faGauge';
import { faListCheck } from '@fortawesome/pro-regular-svg-icons/faListCheck';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons/faTrashCan';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';

import { faFileContract } from '@fortawesome/pro-light-svg-icons/faFileContract';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';

import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faMailchimp } from '@fortawesome/free-brands-svg-icons/faMailchimp';

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    // Icons won't be rendered until they are added to the library which limits the size of the JavaScript pack
    library.add(
      // Duotone icons
      faCircleCheckDuotone,
      faCircleUser,

      // Solid icons
      faAddressCard,
      faAngleLeft,
      faAngleRight,
      faBoxArchive,
      faArrowLeft,
      faCaretDown,
      faCamera,
      faCheck,
      faCircleCheck,
      faSquareCheck,
      faCircle,
      faGear,
      faEllipsis,
      faEllipsisVertical,
      faEnvelopeS,
      faArrowUpRightFromSquare,
      faFileArrowDown,
      faFlagCheckered,
      faGift,
      faGripVertical,
      faHouseS,
      faHeartCrack,
      faKey,
      faMinus,
      faPlus,
      faPlug,
      faShieldCheck,
      faRightToBracket,
      faSkullCrossbones,
      faListCheckS,
      faXmark,
      faCircleXmark,
      faTruckMoving,
      faEye,
      faPencil,
      faPrint,

      // Regular icons
      faCalendar,
      faCalendarDays,
      faCalendarExclamation,
      faCalendarTimes,
      faCalendarWeek,
      faClock,
      faClone,
      faCloudArrowUp,
      faDesktop,
      faDollarSign,
      faEnvelope,
      faFileSignature,
      faFaceFrownOpen,
      faHouse,
      faInfoCircle,
      faList,
      faMobile,
      faMagnifyingGlass,
      faTablet,
      faGauge,
      faListCheck,
      faTrashCan,
      faUsers,

      // Light icons
      faFileContract,
      faFileInvoice,
      faSpinnerThird,

      // Brand icons
      faCcAmex,
      faCcDiscover,
      faCcMastercard,
      faCcVisa,
      faMailchimp,
    );
  }

  connect() {
    // Kicks off the process of finding <i> tags and replacing with <svg>
    dom.watch();
  }
}
