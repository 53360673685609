import AddressAutocompleteController from './address_autocomplete_controller';

export default class InspectionAddressFormController extends AddressAutocompleteController {
  connect() {
    super.connect();

    const controller = this;

    const addressInputElements = document.querySelectorAll('.address-input');
    for (let i = 0; i < addressInputElements.length; i += 1) {
      const eachElement = addressInputElements[i];
      eachElement.addEventListener('blur', () => {
        controller.updateMap();
      });
    }

    this.updateMap();
  }

  placeChanged() {
    super.placeChanged();
    this.updateMap();
  }

  buildAddressString() {
    let addressString = '';
    const street = this.street1Target.value;
    const street2 = this.street2Target.value;
    const city = this.cityTarget.value;
    const state = this.stateTarget.value;
    const zip = this.zipTarget.value;

    if (this.present(street)) {
      addressString += street;
    }

    if (this.present(street2)) {
      if (addressString.length > 0) {
        addressString += ', ';
      }

      addressString += street2;
    }

    if (this.present(city)) {
      if (addressString.length > 0) {
        addressString += ', ';
      }

      addressString += city;
    }

    if (this.present(state)) {
      if (addressString.length > 0) {
        addressString += ', ';
      }

      addressString += state;
    }

    if (this.present(zip)) {
      if (addressString.length > 0) {
        addressString += ', ';
      }

      addressString += zip;
    }

    return addressString;
  }

  updateMap() {
    const mapContainer = document.getElementById('map-container');

    if (mapContainer == null) {
      // Running as part of the test suite, skip map processing since Google will reject the request anyway
      return;
    }

    const newAddressString = encodeURI(this.buildAddressString());
    let map = '';

    if (newAddressString === this.addressString) {
      return;
    }

    this.addressString = newAddressString;

    if (newAddressString.length > 0) {
      map = `<iframe frameborder="0" width="100%" height="325" style="border:0" src="https://www.google.com/maps/embed/v1/search?key=${this.googleApiKey}&q=${newAddressString}" allowfullscreen></iframe>`;
    }

    mapContainer.innerHTML = map;
  }

  present(str) {
    return !(str === null || str.match(/^\s*$/) !== null);
  }
}
