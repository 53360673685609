import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

export default class AsyncFormController extends Controller {
  static targets = ['input', 'html', 'loading'];

  static values = { url: String };

  async submit(e) {
    if (!(e == null)) {
      e.preventDefault();
    }

    if (this.hasLoadingTarget) this.loadingTarget.classList.toggle('d-none');
    const response = await post(this.urlValue, {
      body: this.formData,
    });
    if (response.ok) {
      this.htmlTarget.innerHTML = await response.html;
    }
  }

  get formData() {
    const params = new FormData();
    this.inputTargets.forEach((input) => {
      params.append(input.name, input.value);
    });
    return params;
  }
}
