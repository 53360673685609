import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['autocomplete', 'spinner'];

  static values = {
    openInNewTab: Boolean,
  };

  connect() {
    this.autocompleteTarget.addEventListener('autocomplete.change', (event) => {
      this.onSelection(event);
    });

    this.autocompleteTarget.addEventListener('loadstart', () => {
      this.showSpinner(true);
    });

    this.autocompleteTarget.addEventListener('loadend', () => {
      this.showSpinner(false);
    });
  }

  onSelection(event) {
    const destinationUrl = event.detail.value;

    if (this.openInNewTabValue) {
      window.open(destinationUrl, '_blank');
    } else {
      window.location.href = destinationUrl;
    }
  }

  showSpinner(shouldShow) {
    if (shouldShow) {
      this.spinnerTarget.style.display = 'block';
    } else {
      this.spinnerTarget.style.display = 'none';
    }
  }
}
