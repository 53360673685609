import { Controller } from '@hotwired/stimulus';
import { GoogleCharts } from 'google-charts';

export default class extends Controller {
  connect() {
    this.initCharts();
  }

  waitForModal() {
    // Start a timer to wait for the modal animation to finish. Without this the chart size is unstable.
    this.refreshTimer = setInterval(() => {
      this.drawOverviewCharts();

      // Stop the timer
      clearInterval(this.refreshTimer);
    }, 0.3 * 1000);
  }

  drawOverviewCharts() {
    const charts = JSON.parse(this.data.get('charts'));

    charts.forEach((chart) => {
      this.drawChart(chart.title, chart.data_table, chart.key);
    });
  }

  drawChart(title, dataTable, divID) {
    const data = GoogleCharts.api.visualization.arrayToDataTable(dataTable);
    const frame = document.getElementById(divID);

    const options = {
      curveType: 'function',
      height: frame.clientHeight,
      width: frame.clientWidth,
      title,
      vAxis: {
        title: 'File Size in MB',
      },
      legend: {
        position: 'none',
      },
      trendlines: {
        0: {
          type: 'linear',
          color: 'green',
          lineWidth: 3,
          opacity: 0.3,
          visibleInLegend: true,
        },
      },
    };

    const chart = new GoogleCharts.api.visualization.LineChart(frame);
    chart.draw(data, options);
  }

  initCharts() {
    GoogleCharts.load(this.waitForModal.bind(this));
  }
}
