import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['primaryTemplate', 'addButton', 'orderContainer'];

  connect() {
    this.busy = false;
    this.updateAddButtonVisibility();
  }

  updateAddButtonVisibility() {
    if (this.primaryActionChosen()) {
      this.addButtonTarget.style.visibility = 'visible';
    } else {
      this.addButtonTarget.style.visibility = 'hidden';
    }
  }

  primaryActionChosen() {
    return (this.primaryTemplateTarget.value !== '');
  }

  toggleBusyIndicator() {
    this.busy = !this.busy;

    if (this.busy) {
      this.previousContents = this.addButtonTarget.innerHTML;
      this.addButtonTarget.innerHTML = '<i class="fa-light fa-spinner-third fa-spin"></i>';
    } else {
      this.addButtonTarget.innerHTML = this.previousContents;
    }
  }

  addNewRow() {
    const token = (document.querySelector('[name=csrf-token]') || {}).content || '';

    $.ajax({
      type: 'POST',
      url: '/integration_actions',
      dataType: 'html',
      context: this,
      data: {
        user_id: this.data.get('userId'),
        id: this.data.get('actionId'),
        authenticity_token: token,
      },

      success(data) {
        this.orderContainerTarget.insertAdjacentHTML('beforeend', data);
      },
    }).done(function onDoneAddingRow() {
      this.toggleBusyIndicator();
    });
  }

  // UI responders

  onChangePrimaryTemplate() {
    this.updateAddButtonVisibility();
  }

  onAddButton() {
    if (this.busy) {
      return;
    }

    this.toggleBusyIndicator();
    this.addNewRow();
  }
}
