import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    const colorOptionElementArray = this.element.getElementsByClassName('dropdown-item');

    if (colorOptionElementArray == null) return;

    for (let i = 0; i < colorOptionElementArray.length; i += 1) {
      colorOptionElementArray[i].addEventListener('click', (event) => this.onColorSelected(event));
    }
  }

  onColorSelected(event) {
    const { currentTarget } = event;
    const { color } = currentTarget.dataset;
    this.inputTarget.value = color;

    // Make sure a change event is broadcast so that portal_preview_controller works
    const evt = new CustomEvent('change');
    this.inputTarget.dispatchEvent(evt);
  }
}
