import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';
import { Modal } from 'bootstrap';

// When bootstrap modals are used to initiate an action that results in a turbo frame update, the modal will remain visible on the screen by default. In order to dismiss it, we need to process the request manually and wait for it to complete before manually dismissing the modal.
export default class extends Controller {
  connect() {
    const controller = this;
    this.element.addEventListener('click', (e) => { controller.onClick(e); });
  }

  async onClick() {
    const request = new FetchRequest(this.data.get('method'), this.data.get('url'));
    await request.perform();

    // Request.js will handle any turbo frame updates automatically. All that needs to be done is to dismiss the bootstrap modal.
    const myModalEl = document.getElementById(this.data.get('name'));
    const modal = Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
    modal.hide();
  }
}
