import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'tokenInput',
    'errorContainer',
    'errorDiv',
  ];

  connect() {
    this.findExistingCardData();
    this.initializeForm();
  }

  // It's expected that any existing card data has been encoded somewhere on the page. It's not passed into the controller to avoid having to evaluate the subscription multiple times during the page load.
  findExistingCardData() {
    this.existingCard = {};
    const existingCardDataElement = document.querySelector('#existing-card-data');

    if (!(existingCardDataElement == null)) {
      try {
        this.existingCard = JSON.parse(existingCardDataElement.innerHTML);
      } catch {
        // This can happen if there was no existing payment method. The user will have to enter all the information from scratch.
      }
    }
  }

  initializeForm() {
    // It's expected that the Chargify JavaScript is loaded via a script tag on the page loading this controller
    this.chargify = new Chargify(); // eslint-disable-line no-undef

    this.chargify.load({
      publicKey: this.data.get('publickey'),
      securityToken: this.data.get('securitytoken'),

      // payment profile type you will accept
      type: 'card',

      // points to your Advanced Billing site
      serverHost: `https://${this.data.get('subdomain')}.chargify.com`,

      addressDropdowns: true,
      creditCardExpirationDateDropdowns: true,

      fields: {
        firstName: {
          selector: '#chargify-field-first-name',
          value: this.existingCard.first_name,
          required: true,
        },
        lastName: {
          selector: '#chargify-field-last-name',
          value: this.existingCard.last_name,
          required: true,
        },
        number: {
          selector: '#chargify-field-number',
        },
        month: {
          selector: '#chargify-field-month',
        },
        year: {
          selector: '#chargify-field-year',
        },
        cvv: {
          selector: '#chargify-field-cvv',
          required: true,
        },
        address: {
          selector: '#chargify-field-address-1',
          value: this.existingCard.billing_address,
          required: true,
        },
        address2: {
          selector: '#chargify-field-address-2',
          value: this.existingCard.billing_address2,
        },
        city: {
          selector: '#chargify-field-city',
          value: this.existingCard.billing_city,
          required: true,
        },
        state: {
          selector: '#chargify-field-state',
          value: this.existingCard.billing_state,
          required: true,
        },
        zip: {
          selector: '#chargify-field-zip',
          value: this.existingCard.billing_zip,
          required: true,
        },
        country: {
          selector: '#chargify-field-country',
          value: this.existingCard.billing_country,
          required: true,
        },
      },
    });
  }

  onSubmit(event) {
    event.preventDefault();

    this.errorContainerTarget.style.display = 'none';
    this.errorDivTarget.innerHTML = '';
    const controller = this;

    this.chargify.token(
      this.element,
      (token) => {
        // optionally, you can assign a chargify token to a hidden field
        // or pass it to your backend in other way
        controller.tokenInputTarget.value = token;
        // and then submit the form
        controller.element.submit();
      },
      (err) => {
        // be aware that an error can occur for different reasons
        // while saving billing info in the gateway or directly
        // on the backend. It is rare but still possible.
        // Remember to make the user aware the presence of an error
        let message = 'An unknown error occurred';

        if (!(err.message == null)) {
          message = err.message;
        } else if (!(err.errors == null)) {
          message = err.errors.join(' ');
        } else if (!(err.status == null)) {
          message = `An unknown error occurred, status ${err.status}`;
        }

        controller.errorContainerTarget.style.display = 'block';
        controller.errorDivTarget.innerHTML = message;
      },
    );
  }
}
