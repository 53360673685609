import $ from 'jquery';

class SessionHelper {
  static setParam(group, param, value) {
    $.ajax({
      type: 'POST',
      url: '/sessions/set_param',
      dataType: 'json',
      data: {
        group,
        param,
        value,
      },
    });
  }
}

export default SessionHelper;
