import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['message'];

  connect() {
    this.refreshInterval = 2;

    this.load();
    this.startRefreshing();
  }

  disconnect() {
    this.stopRefreshing();
  }

  async load() {
    const response = await get(this.data.get('url'));

    if (response.ok) {
      const results = await response.json;
      if (results.complete) {
        window.location.href = results.url;
      } else if (results.message) {
        this.messageTarget.innerHTML = results.message;
      }
    }
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load();
    }, this.refreshInterval * 1000);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
