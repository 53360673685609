import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  onClick(event) {
    const { url } = event.currentTarget.dataset;

    if (url == null) {
      return;
    }

    // Avoid overriding any links inside of the container
    if (event.target.matches('a, a *, textarea, button, button *')) {
      return;
    }

    if (event.metaKey) {
      // Command+Click, open in a new tab
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }
}
