import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['transactionButton', 'amountButton', 'transactionDataContainer', 'amountDataContainer'];

  connect() {
    const controller = this;
    this.transactionButtonTarget.addEventListener('click', (e) => { controller.showTransactionData(e); });
    this.amountButtonTarget.addEventListener('click', (e) => { controller.showAmountData(e); });
  }

  showTransactionData(event) {
    event.preventDefault();
    this.amountDataContainerTarget.style.display = 'none';
    this.transactionDataContainerTarget.style.display = 'block';

    this.transactionButtonTarget.classList.remove('btn-ti-gray-one');
    this.amountButtonTarget.classList.remove('btn-ti-blue-eleven');

    this.transactionButtonTarget.classList.add('btn-ti-blue-eleven');
    this.amountButtonTarget.classList.add('btn-ti-gray-one');
  }

  showAmountData(event) {
    event.preventDefault();
    this.amountDataContainerTarget.style.display = 'block';
    this.transactionDataContainerTarget.style.display = 'none';

    this.transactionButtonTarget.classList.remove('btn-ti-blue-eleven');
    this.amountButtonTarget.classList.remove('btn-ti-gray-one');

    this.transactionButtonTarget.classList.add('btn-ti-gray-one');
    this.amountButtonTarget.classList.add('btn-ti-blue-eleven');
  }
}
