import { Controller } from '@hotwired/stimulus';
import { DateTime } from 'luxon';
import { Loader } from '@googlemaps/js-api-loader';

export default class AddressAutocompleteController extends Controller {
  static targets = ['street1', 'street2', 'city', 'state', 'zip', 'country', 'lat', 'lng', 'geocodedat'];

  connect() {
    this.shouldGeocode = (this.data.get('geocode') != null);

    // Don't submit the form if the user presses enter in the autocomplete controller
    this.street1Target.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      } else if (this.shouldGeocode) {
          this.clearGeocoding();
        }
    });

    // If we are capturing geocoding data, make sure to clear it if the user manually changes important address fields since it will no longer be accurate
    if (this.shouldGeocode) {
      this.cityTarget.addEventListener('keydown', () => {
        this.clearGeocoding();
      });

      this.stateTarget.addEventListener('keydown', () => {
        this.clearGeocoding();
      });

      this.zipTarget.addEventListener('keydown', () => {
        this.clearGeocoding();
      });

      if (this.hasCountryTarget) {
        this.countryTarget.addEventListener('keydown', () => {
          this.clearGeocoding();
        });
      }
    }

    this.googleApiKey = this.data.get('apikey');

    if (!(this.googleApiKey == null)) {
      this.initGooglePlacesAutocomplete();
    }
  }

  initGooglePlacesAutocomplete() {
    const fields = ['address_components'];

    if (this.shouldGeocode) {
      fields.push('geometry');
    }

    this.loader = new Loader({
      apiKey: this.googleApiKey,
      version: 'weekly',
      libraries: ['places'],
    });

    this.loader
      .load()
      .then((google) => {
        this.autocomplete = new google.maps.places.Autocomplete(this.street1Target);
        this.autocomplete.setFields(fields);
        this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
      });
  }

  clearGeocoding() {
    this.latTarget.value = '';
    this.lngTarget.value = '';
    this.geocodedatTarget.value = '';
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();

    this.street1Target.value = '';
    this.street2Target.value = '';
    this.cityTarget.value = '';
    this.stateTarget.value = '';
    this.zipTarget.value = '';

    if (this.shouldGeocode) {
      this.clearGeocoding();
    }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    let streetAddress = '';
    let floor = null;
    let streetNumber = null;
    let route = null;

    if (place.address_components == null) return;

    for (let i = 0; i < place.address_components.length; i += 1) {
      const addressType = place.address_components[i].types[0];

      if (addressType === 'floor') {
        floor = place.address_components[i].short_name;
      }

      if (addressType === 'street_number') {
        streetNumber = place.address_components[i].short_name;
      }

      if (addressType === 'route') {
        route = place.address_components[i].short_name;
      }

      if (addressType === 'locality') {
        const val = place.address_components[i].long_name;
        this.cityTarget.value = val;
      }

      if (addressType === 'administrative_area_level_1') {
        const val = place.address_components[i].short_name;
        this.stateTarget.value = val;
      }

      if (addressType === 'postal_code') {
        const val = place.address_components[i].short_name;
        this.zipTarget.value = val;
      }

      if ((addressType === 'country') && this.hasCountryTarget) {
        const val = place.address_components[i].short_name;
        this.countryTarget.value = val;
      }
    }

    if (floor != null) {
      streetAddress += floor;
    }

    if (streetNumber != null) {
      if (streetAddress.length > 0) {
        streetAddress += ', ';
      }

      streetAddress += streetNumber;
    }

    if (route != null) {
      streetAddress += ` ${route}`;
    }

    this.street1Target.value = streetAddress;

    if (!this.shouldGeocode) {
      return;
    }

    this.latTarget.value = place.geometry.location.lat();
    this.lngTarget.value = place.geometry.location.lng();
    this.geocodedatTarget.value = DateTime.local().toUTC().toISO();
  }
}
