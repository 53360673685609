import { Controller } from '@hotwired/stimulus';
import { GoogleCharts } from 'google-charts';
import utils from 'Common/utils';

export default class extends Controller {
  connect() {
    this.initCharts();
  }

  drawCharts() {
    const charts = JSON.parse(this.data.get('charts'));

    this.drawOverviewChart(charts.title, charts.overview, 'overview-chart');
    this.drawGrowthChart('users', charts.growth, 'growth-chart');
  }

  drawOverviewChart(title, dataTable, divID) {
    // Decimal properties that were converted into strings by rails and need to be fixed
    const adjustedData = dataTable.map((row, index) => {
      if (index === 0) return row;

      return [
        row[0],
        row[1],
        utils.localStringToNumber(row[2]),
      ];
    });
    const data = GoogleCharts.api.visualization.arrayToDataTable(adjustedData);

    const options = {
        curveType: 'function',
        height: 350,
        // Gives each series an axis that matches the vAxes number below.
        series: {
          0: { targetAxisIndex: 0 },
          1: { targetAxisIndex: 1 },
        },
        vAxes: {
          // Adds titles to each axis.
          0: { title },
          1: { title: 'MRR', format: 'currency' },
        },
        legend: { position: 'top', alignment: 'center' },
    };

    const chart = new GoogleCharts.api.visualization.LineChart(document.getElementById(divID));
    chart.draw(data, options);
  }

  drawGrowthChart(title, dataTable, divID) {
    const data = GoogleCharts.api.visualization.arrayToDataTable(dataTable);

    const options = {
      height: 350,
      vAxis: { title },
      seriesType: 'bars',
      series: { 0: { type: 'steppedArea' } },
      legend: { position: 'top', alignment: 'center' },
      isStacked: true,
    };

    const chart = new GoogleCharts.api.visualization.ComboChart(document.getElementById(divID));
    chart.draw(data, options);
  }

  initCharts() {
    GoogleCharts.load(this.drawCharts.bind(this));
  }
}
