import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    // Get the initial data right now
    this.refresh();

    // Kick off the timer to automatically refresh the data every so often
    this.startRefreshing();
  }

  disconnect() {
    this.stopRefreshing();
  }

  validDate(str) {
    const t = str.match(/^(\d{2})-(\d{2})-(\d{4})$/);

    if (t !== null) {
      const d = +t[2]; const m = +t[1]; const
        y = +t[3];
      const date = new Date(y, m - 1, d);

      if (date.getFullYear() === y && date.getMonth() === m - 1) {
        return true;
      }
    }

    return false;
  }

  refresh() {
    const startDate = document.getElementById('recent_activity_date_field').value;

    if (this.validDate(startDate)) {
      this.loadData(startDate);
    } else {
      const myModalEl = document.getElementById('date-picker-error');
      const modal = Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
      modal.show();
    }
  }

  loadData(startDate) {
    $.ajax({
      type: 'GET',
      url: '/admin/dashboard/recent_activity',
      dataType: 'html',
      data: {
        start: startDate,
      },
      success(data) {
        $('.recent-activity-container').html(data);
      },
    });
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.refresh();
    }, 5 * 60 * 1000); // Refresh every 5 minutes
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
