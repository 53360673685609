import { Controller } from '@hotwired/stimulus';
import { GoogleCharts } from 'google-charts';

export default class extends Controller {
  static targets = ['iosModelContainer', 'iosOsVersionContainer'];

  connect() {
    this.initCharts();
  }

  drawOverviewCharts() {
    const iosChartData = JSON.parse(this.data.get('ios'));

    this.drawChart(iosChartData.hw.chart, this.iosModelContainerTarget);
    this.drawChart(iosChartData.os.chart, this.iosOsVersionContainerTarget);
  }

  drawChart(dataTable, target) {
    const colorArray = [
      '#5D3F73',
      '#876C9B',
      '#A793B6',
      '#BFB1C9',
      '#D6CDDC',
      '#442E54',
    ];

    const data = GoogleCharts.api.visualization.arrayToDataTable(dataTable);

    const options = {
      pieHole: 0.4,
      legend: 'none',
      chartArea: {
        top: 10,
        bottom: 10,
        width: '100%',
        height: '100%',
      },
      colors: colorArray,
    };

    const chart = new GoogleCharts.api.visualization.PieChart(target);
    chart.draw(data, options);
  }

  initCharts() {
    GoogleCharts.load(this.drawOverviewCharts.bind(this));
  }
}
