import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // Attempt to get the time zone from the browser
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    if (!(timeZone == null)) {
      this.element.setAttribute('value', timeZone);
    }
  }
}
