/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import Bugsnag from '@bugsnag/js';
import { Modal } from 'bootstrap';
import $ from 'jquery';
import utils from 'Common/utils';

export default class extends Controller {
  connect() {
    if (!(this.data.get('statusurl') == null)) {
      this.getInvoiceStatus();
      this.startWatchingInvoiceStatus();
    }

    // The checkout iframe will post a window event with instructions on what to do with the UI when checkout has completed
    window.document.addEventListener('propay-checkout-callback', this.onCheckoutCallback, false);
  }

  disconnect() {
    this.stopWatchingInvoiceStatus();
  }

  // Start a timer to repeatedly asynchronously watch the status of the invoice to detect changes
  startWatchingInvoiceStatus() {
    this.refreshTimer = setInterval(() => {
      this.getInvoiceStatus();
    }, 4000);
  }

  stopWatchingInvoiceStatus() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  getInvoiceStatus() {
    $.ajax({
      type: 'GET',
      url: this.data.get('statusurl'),
      dataType: 'json',
      context: this,
      success(data) {
        const { invoiceId, amountDue } = data;
        this.evaluateInvoiceStatus(invoiceId, utils.localStringToNumber(amountDue));
      },
    });
  }

  evaluateInvoiceStatus(invoiceId, amountDue) {
    if ((invoiceId == null) || (amountDue == null)) {
      Bugsnag.notify(new Error('Bad data evaluating invoice status'));
      return;
    }

    // If this is the first time evaluating the status, save the initial values
    if (this.invoiceId == null) {
      this.invoiceId = invoiceId;
      this.amountDue = amountDue;
    }

    // If nothing has changed since last time (and a balance is still due), just return
    if ((invoiceId === this.invoiceId) && (amountDue === this.amountDue) && (amountDue > 0)) {
      return;
    }

    // Something changed! Either a payment was recorded asynchronously or a team member changed something about the invoice. Either way, the state of the checkout widget is now invalid. Force refresh the page which will show the updated invoice status.
    window.location.replace(window.location.pathname);
  }

  onCheckoutCallback(event) {
    if (event.detail.result === 'cancel') {
      // The checkout was canceled, so just hide the modal
      const myModalEl = document.getElementById('checkoutModal');
      const modal = Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
      modal.hide();
    } else if (event.detail.result === 'success') {
      // Refresh the page and show a payment success message
      window.location.replace(`${window.location.pathname}?payment_succeeded=1`);
    } else {
      // Not sure how we got here, force refresh the page to show any updates
      window.location.replace(window.location.pathname);
    }
  }
}
