import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['placeholderContainer', 'formContainer', 'textInput'];

  onEdit(event) {
    this.placeholderContainerTarget.style.display = 'none';
    this.formContainerTarget.style.display = 'block';
    this.textInputTarget.focus();

    // Prevent the browser from following the link
    event.preventDefault();
  }

  onCancel() {
    this.placeholderContainerTarget.style.display = 'block';
    this.formContainerTarget.style.display = 'none';
  }
}
