import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['yearBlock'];

  yearChanged(event) {
    const newYear = event.target.value;

    this.yearBlockTargets.forEach((target) => {
      const eachYearBlock = target;
      if (eachYearBlock.dataset.year === newYear) {
        eachYearBlock.style.display = 'block';
      } else {
        eachYearBlock.style.display = 'none';
      }
    });
  }
}
