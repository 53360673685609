import { Controller } from '@hotwired/stimulus';
import { Popover } from 'bootstrap';

export default class extends Controller {
  static targets = ['button', 'content'];

  connect() {
    const content = this.contentTarget;

    if (content == null) {
      return;
    }

    this.popover = new Popover(this.buttonTarget, {
      placement: this.data.get('placement'),
      container: 'body',
      html: true,
      trigger: 'focus',
      sanitize: false,
      content: content.innerHTML,
    });
  }
}
