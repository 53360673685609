import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import { get, post } from '@rails/request.js';

//  If you want to show more than one custom asynchronous modal on the same page, you can create your own and specify the selector for the modal like this:
//  .payments-audit-list{ data: { controller: 'async-modal-content', 'async-modal-content-selector': '#refreshPaymentModal' } }
//    = render partial: 'tools/async_content_modal', locals: { id: 'refreshPaymentModal', title: 'Refresh Payment from Paystand'}

export default class extends Controller {
  showInModal(event) {
    event.preventDefault();
    const url = event.target.href;

    if (url == null) {
      return;
    }

    const urlMethod = event.target.dataset.turboMethod;

    //  Find the modal elements now (as opposed to in the connect method) because if the modal is also being loaded asynchronously, the references may no longer be valid if the async_modal_fixer_controller is being used
    this.modalId = this.data.get('selector') || '#asyncModal';
    this.placeholder = document.querySelector(`${this.modalId} #placeholder`);
    this.asyncContentContainer = document.querySelector(`${this.modalId} #async-content-container`);

    this.resetAndPresentModal();
    this.load(url, urlMethod);
  }

  resetAndPresentModal() {
    this.asyncContentContainer.innerHTML = '';
    this.showPlaceholder(true);

    const myModalEl = document.querySelector(this.modalId);
    const modal = Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
    modal.show();
  }

  async load(url, urlMethod) {
    let response;

    if (urlMethod === 'post') {
      response = await post(url);
    } else {
      response = await get(url);
    }

    if (response.ok) {
      this.asyncContentContainer.innerHTML = await response.html;
    } else {
      this.asyncContentContainer.innerHTML = 'Request failed :(';
    }

    this.showPlaceholder(false);
  }

  showPlaceholder(shouldShow) {
    if (shouldShow) {
      this.placeholder.style.display = 'block';
    } else {
      this.placeholder.style.display = 'none';
    }
  }
}
