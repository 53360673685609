import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'optionsTemplate'];

  static values = {
    addOptionsWhenSelected: String, // Set this to only add the options if it matches the selected value
  };

  addOptions(e) {
    if (!this.hasSelectTarget) return;

    if (this.hasAddOptionsWhenSelectedValue && (e.target.value !== this.addOptionsWhenSelectedValue)) {
      this.selectTarget.innerHTML = this.selectOriginalOptionsHTML;
      return;
    }
    this.selectTarget.insertAdjacentHTML('beforeend', this.optionsTemplateTarget.innerHTML);
  }

  get selectOriginalOptionsHTML() {
    const tempDiv = document.createElement('div');
    Array.from(this.selectTarget.options).forEach((option) => {
      if (!this.dynamicOptionValuesArray.includes(option.value)) {
        tempDiv.appendChild(option);
      }
    });
    return tempDiv.innerHTML;
  }

  get dynamicOptions() {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = this.optionsTemplateTarget.innerHTML;
    return Array.from(tempDiv.querySelectorAll('option'));
  }

  get dynamicOptionValuesArray() {
    return this.dynamicOptions.map((option) => option.value);
  }
}
