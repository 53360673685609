import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    function getDimensionsForHeight(maxHeight, aspect) {
      const dimensions = {};
      dimensions.height = maxHeight;
      dimensions.width = Math.round(dimensions.height * aspect);
      return dimensions;
    }

    function adjustVideoDimensions() {
      const aspectRatio = 0.56; // This must be adjusted manually if the aspect ratio of the video is changed in the future
      const maxHeight = $(window).height() - $('#media').offset().top;
      const maxWidth = Math.min($(window).width(), 960);

      const heightForMaxWidth = Math.round(maxWidth / aspectRatio);
      const heightIsRestricting = heightForMaxWidth > maxHeight;

      if (heightIsRestricting) {
        const dimensions = getDimensionsForHeight(maxHeight, aspectRatio);
        $('#media').css('maxHeight', dimensions.height);
        $('#media').css('maxWidth', dimensions.width);
      }
    }

    if (this.data.get('isphone')) {
      $(window).resize(() => {
        adjustVideoDimensions();
      });

      adjustVideoDimensions();
    }
  }
}
