//
// This controller requires at least one checkbox of a specific class to be checked to pass form validation.
// This is not a feature baked into HTML itself so it must be done through JavaScript.
//
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const className = this.data.get('class');
    const checkboxArray = this.element.getElementsByClassName(className);

    if (checkboxArray == null) return;

    for (let i = 0; i < checkboxArray.length; i += 1) {
      checkboxArray[i].addEventListener('click', () => this.validateCheckboxes());
    }

    this.validateCheckboxes();
  }

  validateCheckboxes() {
    const className = this.data.get('class');
    const checkboxArray = this.element.getElementsByClassName(className);
    let atLeastOneChecked = false;

    for (let i = 0; i < checkboxArray.length; i += 1) {
      if (checkboxArray[i].checked === true) {
        atLeastOneChecked = true;
      }
    }

    if (atLeastOneChecked === true) {
      for (let i = 0; i < checkboxArray.length; i += 1) {
        checkboxArray[i].required = false;
        checkboxArray[i].setCustomValidity('');
      }
    } else {
      for (let i = 0; i < checkboxArray.length; i += 1) {
        checkboxArray[i].required = true;
        checkboxArray[i].setCustomValidity('You must make at least one selection');
      }
    }
  }
}
