import AsyncFormController from './async_form_controller';

export default class extends AsyncFormController {
  connect() {
    super.connect();

    // If the user presses the enter button while the input is active, perform the async submit action instead of submitting any form the control is embedded in
    this.inputTarget.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.submit(event);
      }
    });

    // Search for phone numbers immediately if an area code is already present
    if (this.inputTarget.value.length > 0) {
      this.submit();
    }
  }
}
