import { Controller } from '@hotwired/stimulus';
import { destroy, put } from '@rails/request.js';

export default class extends Controller {
  async onDismissButton() {
    const response = await destroy(this.data.get('dismiss-url'));

    if (response.ok) {
      this.element.innerHTML = '';
    }
  }

  async onAbortButton() {
    const response = await put(this.data.get('abort-url'));

    if (response.ok) {
      this.element.innerHTML = '';
    }
  }
}
