import { Controller } from '@hotwired/stimulus';
import { GoogleCharts } from 'google-charts';
import utils from 'Common/utils';

export default class extends Controller {
  static targets = ['methodContainer', 'topTeamsContainer', 'brandContainer'];

  connect() {
    this.colorArray = utils.colorArray('18218C', 'EEEFFC', 8);
    this.initCharts();
  }

  drawOverviewCharts() {
    const data = JSON.parse(this.data.get('data'));

    this.drawChart(data.method_chart.chart, this.methodContainerTarget);
    this.drawChart(data.top_teams_chart.chart, this.topTeamsContainerTarget);
    this.drawChart(data.brand_chart.chart, this.brandContainerTarget);
  }

  drawChart(dataTable, target) {
    const data = GoogleCharts.api.visualization.arrayToDataTable(dataTable);

    const options = {
      pieHole: 0.4,
      legend: 'none',
      chartArea: {
        top: 10,
        bottom: 10,
        width: '100%',
      },
      height: 150, // Explicitly setting the height in order to get the charts that are initially hidden to behave correctly
      colors: this.colorArray,
    };

    const chart = new GoogleCharts.api.visualization.PieChart(target);
    chart.draw(data, options);
  }

  initCharts() {
    GoogleCharts.load(this.drawOverviewCharts.bind(this));
  }
}
