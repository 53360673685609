import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.expand = 0;

    this.load();

    if (this.data.get('refreshInterval') > 0) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  load() {
    $.ajax({
      type: 'GET',
      url: this.data.get('url'),
      dataType: 'html',
      context: this,
      data: {
        expand: this.expand,
      },

      success(data) {
        this.containerTarget.innerHTML = data;
      },
    });
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load();
    }, this.data.get('refreshInterval') * 1000);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  onToggleExpanded() {
    if (this.expand === 1) {
      this.expand = 0;
    } else {
      this.expand = 1;
    }

    this.load();
  }
}
