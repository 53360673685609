import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  connect() {
    this.activeInterval = 2;
    this.idleInterval = 10;
    this.isActive = false;
    this.refreshInterval = this.idleInterval;

    this.load();
    this.startRefreshing();
  }

  disconnect() {
    this.stopRefreshing();
  }

  async load() {
    const response = await get(this.data.get('url'));

    if (response.ok) {
      const content = await response.html;
      this.element.innerHTML = content;
      this.updateRefreshRate(content.length > 0);
    }
  }

  updateRefreshRate(dataPresent) {
    if (this.isActive !== dataPresent) {
      this.isActive = dataPresent;

      this.stopRefreshing();

      if (dataPresent) {
        this.refreshInterval = this.activeInterval;
      } else {
        this.refreshInterval = this.idleInterval;
      }

      this.startRefreshing();
    }
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load();
    }, this.refreshInterval * 1000);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
