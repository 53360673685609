import { Controller } from '@hotwired/stimulus';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxon2Plugin, { toLuxonDateTime } from '@fullcalendar/luxon2';
import { DateTime } from 'luxon';
import { Modal } from 'bootstrap';
import $ from 'jquery';
import SessionHelper from './session_helper';

class WeekCalendarController extends Controller {
  static targets = ['legendCheckbox', 'calendarTitleMonth', 'calendarTitleEtc'];

  connect() {
    this.initCalendar();
    this.startRefreshing();

    $('#newJobModal').on('hidden.bs.modal', () => {
      // When the new job modal closes, clear any hidden fields added by this JavaScript controller
      document.getElementById('hidden-start-time-container').innerHTML = '';
    });
  }

  disconnect() {
    this.stopRefreshing();
  }

  today() {
    return DateTime.local().setZone(this.data.get('timezone')).toISODate();
  }

  initCalendar() {
    const calendarEl = document.getElementById('week-calendar-container');
    const controller = this;
    const startDate = this.data.get('startdate') || controller.today();

    // In some circumstances the calendar does a poor job of clearing itself and there can be duplicate instances on the page when using the browser back button. Solve this by clearing anything inside the calendar container before rendering a new calendar.
    calendarEl.innerHTML = '';

    this.calendar = new Calendar(calendarEl, {
      plugins: [luxon2Plugin, timeGridPlugin, interactionPlugin],
      timeZone: this.data.get('timezone'),
      slotMinTime: this.data.get('mintime'),
      slotMaxTime: this.data.get('maxtime'),
      initialDate: startDate,
      height: 700,
      nowIndicator: true,
      eventSources: [
        {
          id: 'jobs',
          events(info, successCallback) {
            controller.getJobs(info, successCallback);
          },
        },
        {
          id: 'conflicts',
          events(info, successCallback) {
            controller.getConflicts(info, successCallback);
          },
        },
      ],
      headerToolbar: false,
      loading(bool) {
        controller.showLoadingSpinner(bool);
      },
      eventClick(info) {
        controller.onEventClick(info);
      },
      slotLaneContent(arg) {
        return controller.buildHoverCells(arg);
      },
    });

    this.calendar.render();
    this.updateTitle();
  }

  buildHoverCells(arg) {
    const controller = this;
    const slotDate = toLuxonDateTime(arg.date, this.calendar);
    const flexDiv = document.createElement('div');
    flexDiv.className = 'row g-0 hover-row';

    for (let ii = 0; ii < 7; ii += 1) {
      const dayDiv = document.createElement('div');
      dayDiv.className = 'col';
      dayDiv.dataset.day = ii;
      dayDiv.dataset.hour = slotDate.hour;
      dayDiv.dataset.minute = slotDate.minute;

      dayDiv.addEventListener('mouseenter', (event) => {
        const { target } = event;
        const datetime = controller.hoverCellDatetime(target);

        if (datetime >= DateTime.local()) {
          target.innerHTML = `+ ${datetime.toFormat('h:mm a')}`;
          target.className = 'col current-time';
        }
      });

      dayDiv.addEventListener('mouseleave', (event) => {
        const { target } = event;
        target.innerHTML = '';
        dayDiv.className = 'col';
      });

      dayDiv.addEventListener('click', (event) => {
        const datetime = controller.hoverCellDatetime(event.target);

        if (datetime >= DateTime.local()) {
          controller.onTimelineCellButton(datetime.toISO());
        }
      });

      flexDiv.appendChild(dayDiv);
    }

    const arrayOfDomNodes = [flexDiv];
    return { domNodes: arrayOfDomNodes };
  }

  hoverCellDatetime(element) {
    const { day } = element.dataset;
    const { hour } = element.dataset;
    const { minute } = element.dataset;
    return this.currentCalendarDate().plus({ days: day }).set({ hour, minute });
  }

  onTimelineCellButton(datetime) {
    if (this.data.get('autopilotenabled') === '1') {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 'inspection_date');
      input.setAttribute('value', datetime);

      document.getElementById('hidden-start-time-container').appendChild(input);

      const myModalEl = document.getElementById('newJobModal');
      const modal = Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
      modal.show();
    } else {
      window.location.href = `${this.data.get('newjoburl')}?inspection_date=${datetime}`;
    }
  }

  currentCalendarDate() {
    let value = toLuxonDateTime(this.calendar.getDate(), this.calendar);

    // Weeks start on Monday in Luxon regardless of time zone, so it needs to be adjusted so that Sunday is the first day of the week for our case
    if (value.weekday === 7) {
      value = value.startOf('day');
    } else {
      value = value.startOf('week').minus({ days: 1 });
    }

    return value;
  }

  updateTitle() {
    const date = this.currentCalendarDate();

    this.calendarTitleMonthTargets.forEach((target) => {
      const titleTarget = target;
      titleTarget.innerHTML = date.toFormat('MMMM');
    });

    this.calendarTitleEtcTargets.forEach((target) => {
      const titleTarget = target;
      titleTarget.innerHTML = `${date.toFormat('d')} - ${date.plus({ days: 6 }).toFormat('d, y')}`;
    });
  }

  getJobs(info, successCallback) {
    $.ajax({
      type: 'GET',
      url: this.data.get('jobsurl'),
      dataType: 'json',
      data: {
        start: info.start.toISOString(),
        end: info.end.toISOString(),
        hide_users: this.hiddenUsersArray(),
      },
      success(data) {
        successCallback(data.jobs);
      },
    });
  }

  getConflicts(info, successCallback) {
    $.ajax({
      type: 'GET',
      url: this.data.get('conflictsurl'),
      dataType: 'json',
      data: {
        start: info.start.toISOString(),
        end: info.end.toISOString(),
        hide_users: this.hiddenUsersArray(),
      },
      success(data) {
        successCallback(data.conflicts);
      },
    });
  }

  showLoadingSpinner(shouldShow) {
    const spinner = document.body.querySelector('#loading-spinner');

    if (spinner == null) {
      return;
    }

    if (shouldShow) {
      spinner.style.visibility = 'visible';
    } else {
      spinner.style.visibility = 'hidden';
    }
  }

  toggleLegendCheckbox() {
    this.refreshCalendar();
  }

  hiddenUsersArray() {
    const checkedUserIds = [];

    this.legendCheckboxTargets.forEach((target) => {
      if (!target.checked) {
        checkedUserIds.push(target.value);
      }
    });

    return checkedUserIds;
  }

  refreshCalendar() {
    this.calendar.refetchEvents();
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.refreshCalendar();
    }, WeekCalendarController.refreshInterval * 1000);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  onTodayButton() {
    this.calendar.today();
    this.updateTitle();
    SessionHelper.setParam('manage_jobs', 'selected_calendar_date', null);
  }

  onPrevButton() {
    this.calendar.prev();
    this.updateTitle();
    this.saveSelectedCalendarDate();
  }

  onNextButton() {
    this.calendar.next();
    this.updateTitle();
    this.saveSelectedCalendarDate();
  }

  saveSelectedCalendarDate() {
    const date = this.currentCalendarDate();
    SessionHelper.setParam('manage_jobs', 'selected_calendar_date', date.toISODate());
  }

  onEventClick(info) {
    if (info.event.source.id === 'jobs') {
      window.location.href = `/jobs/${info.event.id}`;
    }
  }
}

WeekCalendarController.refreshInterval = (5 * 60); // 5 minutes

export default WeekCalendarController;
