// General reusable functions

// Thanks https://stackoverflow.com/a/59440528/406797
// Calculate the color code at a specific ratio in the gradient between the two specified colors
const calculateMiddleColor = ({
  color1 = 'FF0000',
  color2 = '00FF00',
  ratio,
}) => {
  const hex = (color) => {
    const colorString = color.toString(16);
    return colorString.length === 1 ? `0${colorString}` : colorString;
  };
  const r = Math.ceil(
    parseInt(color2.substring(0, 2), 16) * ratio +
      parseInt(color1.substring(0, 2), 16) * (1 - ratio),
  );
  const g = Math.ceil(
    parseInt(color2.substring(2, 4), 16) * ratio +
      parseInt(color1.substring(2, 4), 16) * (1 - ratio),
  );
  const b = Math.ceil(
    parseInt(color2.substring(4, 6), 16) * ratio +
      parseInt(color1.substring(4, 6), 16) * (1 - ratio),
  );

  return hex(r) + hex(g) + hex(b);
};

// Generate an array of colors based on a gradient between two colors for use in graphs
const colorArray = (color1, color2, count) => {
  const colors = [];

  for (let i = 0; i <= count - 1; i += 1) {
    colors.push(
      `#${calculateMiddleColor({ color1, color2, ratio: i / (count * 1.0) })}`,
    );
  }

  return colors;
};

const dateStringIsValid = (dateString) => {
  const matchedText = dateString.match(/^(\d{2})-(\d{2})-(\d{4})$/);

  if (matchedText !== null) {
    const day = +matchedText[2];
    const month = +matchedText[1];
    const year = +matchedText[3];
    const date = new Date(year, month - 1, day);

    if (date.getFullYear() === year && date.getMonth() === month - 1) {
      return true;
    }
  }

  return false;
};

const formatCurrency = (amount, currency = 'USD', locale = 'en-US') =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

// Extract the last substring between square brackets in the name (i.e. xxx[i][name]), used as a form helper
const lastInputNamePart = (name) => name.match(/\[([^[]*)\][^[]*$/).pop();

// Convert a string to a number, ignoring any currency or non-numerical symbols
const localStringToNumber = (s) => Number(String(s).replace(/[^0-9.-]+/g, ''));

// helper function to reorder result array for drag and drop (src: react-beautiful-dnd docs)
const reorderArrayItem = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const sortByAttributes = (attributes) => (a, b) => {
  if (attributes.length === 0) {
    return 0;
  }
  const sortByAttribute = (aa, bb, ii) => {
    const attribute = attributes[ii];
    if (!attribute) {
      return 0;
    }
    if (aa[attribute] > bb[attribute]) {
      return 1;
    }
    if (aa[attribute] < bb[attribute]) {
      return -1;
    }
    return sortByAttribute(aa, bb, ii + 1);
  };
  return sortByAttribute(a, b, 0);
};

export default {
  calculateMiddleColor,
  colorArray,
  dateStringIsValid,
  formatCurrency,
  lastInputNamePart,
  localStringToNumber,
  reorderArrayItem,
  sortByAttributes,
};
