import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  connect() {
    this.load();

    if (this.data.get('refreshInterval') > 0) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  async load() {
    const response = await get(this.data.get('url'));

    if (response.ok) {
      this.element.innerHTML = await response.html;
    }
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load();
    }, this.data.get('refreshInterval') * 1000);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
