import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'serviceRoleOptions',
    'serviceOptions',
    'calendarInvite',
    'hideIfTextMessage',
    'showIfTextMessage',
    'channel',
    'trixContainer',
    'triggerInput',
  ];

  static outlets = ['trix-hooks'];

  onFilterByRoleChange(event) {
    if (event.target.value === 'true') {
      this.serviceRoleOptionsTarget.style.display = 'block';
    } else {
      this.serviceRoleOptionsTarget.style.display = 'none';
    }
  }

  onFilterByServiceChange(event) {
    if (event.target.value === 'true') {
      this.serviceOptionsTarget.style.display = 'block';
    } else {
      this.serviceOptionsTarget.style.display = 'none';
    }
  }

  onTriggerChange() {
    this.setCalendarInviteVisibility();

    const roleFilter = document.querySelector('#message_template_filter_by_role');
    const trigger = Number(this.triggerInputTarget.value);

    // Between Event::TRIGGER_JOB_SHARED and Event::TRIGGER_SHARED_JOB_ATTACHMENT_ADDED, i.e. Event::SHARING_TRIGGERS
    if ((trigger >= 7) && (trigger <= 9)) {
      roleFilter.options[0].innerHTML = 'Everyone With Access';
    } else {
      roleFilter.options[0].innerHTML = 'Everyone in the Job';
    }
  }

  setCalendarInviteVisibility() {
    const trigger = Number(this.triggerInputTarget.value);

    // Between Event::TRIGGER_JOB_CONFIRMED and Event::TRIGGER_JOB_INSPECTION_DATE
    if ((trigger >= 0) && (trigger <= 3) && !this.isATextMessage()) {
      this.calendarInviteTarget.style.display = 'block';
    } else {
      this.calendarInviteTarget.style.display = 'none';
    }
  }

  deliveryChannelUpdated() {
    if (this.isATextMessage()) {
      this.hideIfTextMessageTargets.forEach((eachTarget) => {
        const target = eachTarget;
        target.style.display = 'none';
      });

      this.showIfTextMessageTargets.forEach((eachTarget) => {
        const target = eachTarget;
        target.style.display = 'block';
      });

      this.trixContainerTarget.id = 'hide-html-controls';

      this.hideRawEditor();
    } else {
      this.hideIfTextMessageTargets.forEach((eachTarget) => {
        const target = eachTarget;
        target.style.display = 'block';
      });

      this.showIfTextMessageTargets.forEach((eachTarget) => {
        const target = eachTarget;
        target.style.display = 'none';
      });

      this.trixContainerTarget.id = '';
    }

    this.setPreviewChannel();
    this.setCalendarInviteVisibility();

    // Refresh the preview
    this.trixHooksOutlet.load();
  }

  hideRawEditor() {
    const trixElement = document.querySelector('.trix-editor-with-preview-container');
    const trixHooksController = this.application.getControllerForElementAndIdentifier(trixElement, 'trix-hooks');
    trixHooksController.closeRawEditorIfNecessary();
  }

  setPreviewChannel() {
    if (!this.hasChannelTarget) return;

    const previewContainer = document.querySelector('.trix-editor-with-preview-container');
    const { trixHooksUrl } = previewContainer.dataset;

    // Set the parameter on the URL that specifies whether HTML is allowed in the preview or not
    previewContainer.dataset.trixHooksUrl = `${trixHooksUrl.replace(/\?text_message=.*/, '')}?text_message=${this.isATextMessage()}`;
  }

  isATextMessage() {
    return (this.hasChannelTarget && (this.channelTarget.value === '1'));
  }
}
