import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  static values = {
    correct: { type: String, default: '0' },
  };

  onUnitsChange(event) {
    if (event.target.value === this.correctValue) {
      this.inputTarget.style.display = 'none';
    } else {
      this.inputTarget.style.display = 'block';
    }
  }
}
