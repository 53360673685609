import { Controller } from '@hotwired/stimulus';
import { formatGeneral } from 'cleave-zen';

export default class extends Controller {
  connect() {
    this.element.addEventListener('input', (e) => {
        this.element.value = formatGeneral(e.target.value, {
          blocks: [0, 3, 3, 4],
          delimiters: ['(', ') ', '-'],
          delimiterLazyShow: true,
          numericOnly: true,
        });
    });
  }
}
