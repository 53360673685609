import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'roleSelect',
    'dateOptions',
  ];

  onRoleChange(event) {
    if (event.target.value === '0') {
      this.roleSelectTarget.style.display = 'none';
    } else {
      this.roleSelectTarget.style.display = 'block';
    }
  }

  onFilterByDateChange(event) {
    if (event.target.value === '1') {
      this.dateOptionsTarget.style.display = 'block';
    } else {
      this.dateOptionsTarget.style.display = 'none';
    }
  }
}
