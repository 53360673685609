import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

export default class AsyncPreviewController extends Controller {
  static targets = ['input', 'container', 'content', 'signature', 'notice'];

  connect() {
    this.refreshIntervalInSeconds = 0.75;
    this.refreshNeeded = false;
    this.startRefreshing();
    this.showContainer = (this.containerTarget.style.display !== 'none');
    this.token = (document.querySelector('[name=csrf-token]') || {}).content || '';

    // This is necessary for Trix controllers because the input is never modified by the user directly, it is only updated programmatically via JavaScript
    const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this.inputTarget), 'value');
    const controller = this;
    Object.defineProperty(this.inputTarget, 'value', {
      set(...args) {
        controller.refreshNeeded = true;
        descriptor.set.apply(this, args);
      },
      get() {
        return descriptor.get.apply(this);
      },
    });

    if (this.hasSignatureTarget) {
      this.signatureTarget.addEventListener('change', () => {
        controller.load();
      });
    }

    // Load the initial preview
    this.load();
  }

  disconnect() {
    this.stopRefreshing();
  }

  async load() {
    let signature = null;

    if (this.hasSignatureTarget) {
      signature = this.signatureTarget.value;
    }

    const response = await post(this.data.get('url'), {
      body: {
        data: this.inputTarget.value,
        job_id: this.data.get('jobid'),
        authenticity_token: this.token,
        signature,
      },
    });

    if (response.ok) {
      const data = await response.json;
      this.contentTarget.innerHTML = data.content;
      this.updateNotice(data.notice);
    } else {
      this.contentTarget.innerHTML = 'Preview failed <i class="fa-solid fa-heart-crack"></i>';
      this.updateNotice('');
    }
  }

  updateNotice(data) {
    if (!(data == null) && (data.length > 0)) {
      this.noticeTarget.style.display = 'block';
      this.noticeTarget.innerHTML = data;
    } else {
      this.noticeTarget.style.display = 'none';
      this.noticeTarget.innerHTML = '';
    }
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      if (this.refreshNeeded) {
        this.refreshNeeded = false;
        this.load();
      }
    }, this.refreshIntervalInSeconds * 1000);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  togglePreviewContainerVisibility(event) {
    this.showContainer = !this.showContainer;
    const { target } = event;

    if (this.showContainer) {
      this.containerTarget.style.display = 'block';
      target.innerHTML = 'Hide Preview';
    } else {
      this.containerTarget.style.display = 'none';
      target.innerHTML = 'Show Preview';
    }
  }
}
