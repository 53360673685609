import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['autocomplete', 'spinner'];

  connect() {
    this.autocompleteTarget.addEventListener('loadstart', () => {
      this.showSpinner(true);
    });

    this.autocompleteTarget.addEventListener('loadend', () => {
      this.showSpinner(false);
    });
  }

  showSpinner(shouldShow) {
    if (shouldShow) {
      this.spinnerTarget.style.display = 'block';
    } else {
      this.spinnerTarget.style.display = 'none';
    }
  }
}
