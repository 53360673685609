import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static outlets = ['direct-upload'];

  connect() {
    const controller = this;
    this.element.addEventListener('turbo:submit-end', () => {
      controller.hideModal();
    });
  }

  hideModal() {
    const myModalEl = document.getElementById(this.data.get('name'));
    const modal = Modal.getOrCreateInstance(myModalEl); // Returns a Bootstrap modal instance
    modal.hide();

    // If there is a direct upload controller connected to this controller, notify it that the modal is closing so that it can reset its UI
    this.directUploadOutlets.forEach((directUpload) => directUpload.reset());
  }
}
