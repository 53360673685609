import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    const PUBLISH_TO_TI = '0';

    // hide rows as appropriate when changing the pickup behavior
    $(this.element).change(function onChange() {
      if ($(this).val() === PUBLISH_TO_TI) {
        $('#completion-row').hide();
      } else {
        $('#completion-row').show();
      }
    });
  }
}
