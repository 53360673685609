import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const controller = this;
    this.element.addEventListener('click', (e) => { controller.onClick(e); });
  }

  onClick(event) {
    const url = this.data.get('url');

    if (url == null) {
      return;
    }

    // Avoid overriding any links inside of the container
    if (event.target.matches('a, a *, textarea, button, button *')) {
      return;
    }

    if (event.metaKey) {
      // Command+Click, open in a new tab
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }
}
