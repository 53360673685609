import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  onDiscard() {
    const name = this.data.get('name');
    const idField = this.element.querySelector(`input[name="${name}[id]"]`);

    if (idField == null) {
      // Clear the contents of the controller div
      this.element.innerHTML = '';
    } else {
      // Make a clone of the ID field so that it can be reinserted after clearing
      const idFieldClone = idField.cloneNode();

      // Clear the contents of the controller div
      this.element.innerHTML = '';

      // Put the ID field back in
      this.element.appendChild(idFieldClone);

      // Create a hidden form element with the proper name and a destroy field
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', `${name}[_destroy]`);
      input.setAttribute('value', 1);
      this.element.appendChild(input);
    }
  }
}
