import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    $('#check-all').click(() => {
      $('input:checkbox').prop('checked', true);
    });

    $('#check-none').click(() => {
      $('input:checkbox').prop('checked', false);
    });
  }
}
