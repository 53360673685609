import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  loadContent(event) {
    const addressValue = event.target.href;
    const container = document.querySelector('#on-demand-content-container');

    event.preventDefault(); // Prevent link from following its href

    if ((container == null) || (addressValue == null)) {
      return;
    }

    container.innerHTML = '';

    $.ajax({
      type: 'GET',
      url: addressValue,
      success(returnedData) {
        container.innerHTML = returnedData;
      },
    });
  }
}
