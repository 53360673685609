import { Controller } from '@hotwired/stimulus';
import { GoogleCharts } from 'google-charts';
import { get } from '@rails/request.js';

export default class extends Controller {
  connect() {
    GoogleCharts.load(this.drawOverviewCharts.bind(this));
  }

  drawOverviewCharts() {
    const charts = JSON.parse(this.data.get('charts'));

    charts.forEach((chart) => {
      if (chart.type === 'histogram') {
        this.drawHistogram(chart.title, chart.data_table, chart.key);
      } else if (chart.type === 'diff') {
        this.drawDiff(chart.title, chart.data_table, chart.key);
      }
    });
  }

  drawHistogram(title, dataTable, divID) {
    const data = GoogleCharts.api.visualization.arrayToDataTable(dataTable);

    const options = {
      height: 350,
      title,
      legend: { position: 'none' },
    };

    const chart = new GoogleCharts.api.visualization.Histogram(document.getElementById(divID));
    chart.draw(data, options);

    const controller = this;
    GoogleCharts.api.visualization.events.addListener(chart, 'select', () => {
      const selection = chart.getSelection();

      if (selection.length > 0) {
        // get position of selected bar
        const chartLayout = chart.getChartLayoutInterface();
        const barBounds = chartLayout.getBoundingBox(`bar#${selection[0].column - 1}#${selection[0].row}`);

        // get rows for selected bar
        const rows = data.getFilteredRows([{
          column: selection[0].column,
          test(value) {
            return ((value >= Math.ceil(chartLayout.getHAxisValue(barBounds.left))) &&
                    (value < Math.floor(chartLayout.getHAxisValue(barBounds.left + barBounds.width))));
          },
        }]);

        // create data view for selected rows
        const view = new GoogleCharts.api.visualization.DataView(data);
        view.setRows(rows);

        // get ids of selected bar
        const ids = view.getDistinctValues(0);
        controller.loadSelection(ids);
      }
    });
  }

  drawDiff(title, dataTable, divID) {
    const { preCompactDataTable, postCompactDataTable } = dataTable;
    const preCompactData = GoogleCharts.api.visualization.arrayToDataTable(preCompactDataTable);
    const postCompactData = GoogleCharts.api.visualization.arrayToDataTable(postCompactDataTable);

    const options = {
      height: 350,
      title,
      hAxis: { title: preCompactDataTable[0][0] },
      vAxis: { title: preCompactDataTable[0][1] },
      legend: { position: 'none' },
    };

    const chart = new GoogleCharts.api.visualization.ScatterChart(document.getElementById(divID));
    const diffData = chart.computeDiff(preCompactData, postCompactData);
    chart.draw(diffData, options);
  }

  async loadSelection(selectedIds) {
    const response = await get(this.data.get('url'), { query: { ids: selectedIds } });

    if (response.ok) {
      const target = document.getElementById('on-demand-content-container');
      target.innerHTML = await response.html;
    }
  }
}
