import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];

  static values = { string: String };

  toggleClass(e) {
    let classes = [];
    if (this.hasStringValue) {
      classes = this.stringValue.split(',');
    } else if (e.params.class) {
      classes = e.params.class.split(',');
    }
    this.utilElement.classList.toggle(...classes);
  }

  get utilElement() {
    if (this.hasElementTarget) {
      return this.elementTarget;
    }
      return this.element;
  }
}
